import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/core/services/api.service';
declare var $: any;
@Component({
    selector: 'app-modal-popup',
    templateUrl: './modal-popup.component.html',
    styleUrls: ['./modal-popup.component.scss'],
})
export class ModalPopupComponent implements OnInit {
    // tslint:disable-next-line:no-input-rename
    @Input('email') email;
    // tslint:disable-next-line:no-input-rename
    @Input('marginTop') marginTop;
    otpValue;
    otpError;
    constructor(private router: Router, private apiService: ApiService) {}

    ngOnInit(): void {
        // this.marginTop = this.marginTop + '%';
     //   console.log('marginTop---', this.marginTop);
       // console.log('email---', this.email);
        this.initModal();
    }

    hideModal() {
        document.getElementById('close-modal').click();
    }

    initModal() {
        $('#myModal').modal('show');
    }
}
