<!-- <p (click)="output()">verify-otp works!</p> -->


<!-- Modal Header -->
<div class="modal-header">
    <h4 class="modal-title ml-auto">Verify OTP</h4>
    <button type="button" class="close" (click)="hideModal()">&times;</button>
</div>

<!-- Modal body -->
<div class="modal-body" style="height: auto;">
    <p class="text-center" *ngIf="otpMessage">OTP has been sent to your registered Email..!!</p>
    <div class="row justify-content-center form-group">
        <input [(ngModel)]="otpValue" class="form-control" name="otp" value="" placeholder="Enter OTP" (keydown.enter)="handleKeyUp($event)">
    </div>
    <div *ngIf="otpError" class="row justify-content-center"><p class="h-25 alert-box pl-2" style="width: 80%;">Incorrect OTP.Pease try again..!!</p></div>
    <div class="row h-25 mt-4 pt-2">
        <div class="col-sm"> <button type="button" class="btn btn-md btn-primary w-100"
                style="background-color: #876c1d; color: white;" (click)="resendOtp()">Resend
                Otp</button></div>
        <div class="col-sm"> <button type="button" style="color: white;"
                class="btn btn-md btn-primary w-100" (click)="sendModal()" style=" background:#7c6526; color: white;border-color: #7c6526;">Confirm</button>
        </div>
        <div class="col-sm"><button type="button" style="background-image: linear-gradient(90deg,#876c1d,#9da063,#7c6526);margin-top: 10px;"
                class="btn btn-lg btn-block bttn gradient-button-red fs-14"
                (click)="hideModal()">Cancel</button>
        </div>
    </div>
    <!-- <p ngIf="otpError==true">**Entered Otp is wrong..!! Try again..!!</p> -->
</div>