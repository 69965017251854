<div class="login-page bg-primary-color">
  <div class="container">
    <div class="row" style="margin-top: 17%;">
      <div class="col-sm-12 col-md-6 ">
        <div class="text-center">
          <a href="./" class="page-ath-logo logInlogo">
            <img src="../../../../../assets/images/logo.png" alt="logo" />
          </a>

          <!-- <p class="fs-24 text-gray-1 font-weight-bold">
                          Unchained <i>Freedom</i> and 
                          <br><i>Equality</i> forever.
                      </p> -->
        </div>
      </div>
      <div class="col-sm-12 col-md-6">
        <!-- <div class="border-white rounded-lg" > -->
        <div class="text-center mb-5">
          <div class="text-center">
            <h1 class="welcome">Welcome to Viblo.</h1>
            <br />
            <!-- <p class="color">
              Fastest & Secure Financial Solution
              <br />
              For Institution & Community
            </p> -->
          </div>
          <!-- <a  class="text-white">Skip</a> -->
        </div>
        <!-- </div> -->
      </div>

      <!-- <div class="d-flex w-100 align-center"> -->
      <!-- column -->

      <!-- </div> -->
    </div>
    <div class="text-center" style="display: flex; justify-content: center;">
      <button class="btn btn-primary" (click)="click()">
        Skip
      </button>
    </div>
  </div>
</div>