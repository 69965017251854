import { Component, OnInit } from '@angular/core';
import { ApiService } from "src/app/core/services/api.service";
import { Router, ActivatedRoute } from '@angular/router';


@Component({
  selector: 'app-verify',
  templateUrl: './verify.component.html',
  styleUrls: ['./verify.component.scss']
})
export class VerifyComponent implements OnInit {

  verfystatus: boolean = true;
  bckendstatus: any = 'Already registered';
  email:any;
  token:any;

  constructor(private apiservice: ApiService, private router: Router, private route: ActivatedRoute) { }

  ngOnInit() {
    this.route.queryParamMap.subscribe((queryParams) => {
      console.log("queryparamsss", queryParams)
      this.email = queryParams.get("email");
      this.token = queryParams.get("token");
      console.log("email", this.email, "tokee", this.token)

      if (this.email !== null && this.token !== "") {
        this.verify();
      }
    });

}

verify(){
  this.apiservice.registerConfirm({"email":this.email,"token":this.token}).subscribe((data: any) => {
    if (data.status === 'success') {
      this.verfystatus = true;
      this.bckendstatus = data.message;
    }
  },
    (err) => {
      
    });
}

}
