import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ApiService } from 'src/app/core/services/api.service';
import { Route } from '@angular/compiler/src/core';
import { Router } from '@angular/router';
import { MustMatch } from '../../home/register/mustMatch';

@Component({
    selector: 'app-createpin',
    templateUrl: './createpin.component.html',
    styleUrls: ['./createpin.component.scss']
})
export class CreatepinComponent implements OnInit {

    changePinError = false;
    submitted = false;
    EyeFlag: boolean;
    repeatEyeFlag: boolean;
    changePinForm = this.fb.group(
        {
            newPin: ['', [Validators.required]],
            confirmNewPin: ['', Validators.required],
        },
        {
            validator: MustMatch('newPin', 'confirmNewPin'),
        }
    );

    constructor(
        private fb: FormBuilder,
        private apiService: ApiService,
        private router: Router
    ) { }

    ngOnInit(): void { }

    get f() {
        return this.changePinForm.controls;
    }

    onClick() {
        this.EyeFlag = !this.EyeFlag;
    }

    onClickRepeat() {
        this.repeatEyeFlag = !this.repeatEyeFlag
    }

    onSubmit() {
        this.submitted = true;
       // console.log(this.changePinForm.invalid);
        if (this.changePinForm.invalid) {
            return;
        } else {
        //    console.log(this.f);
            const newPin = this.f.newPin.value;
            const obj = {
                newPin,
            };
            this.apiService.changePin(obj).subscribe(
                (data: any) => {
                 //   console.log(data);
                    if (data.status === 'success') {
                        this.router.navigate(['/pages/dashboard']);
                    }
                },
                (err) => {
                    this.changePinError = true;
                //    console.log(err);
                }
            );
        }
    }

}
