<div (keydown.enter)="handleKeyUp($event)">
  <div class="page-ath-wrap lr-main">

    <div class="page-ath-content">
      <!-- <div class="page-ath-header">
        <a href="./" class="page-ath-logo logInlogo"
          ><img src="assets/images/app-logo.png" alt="logo"
        /></a>
      </div> -->
      <div class="page-ath-form">
        
        <div class="mob-logo mb-5">
          <img src="assets/images/logo.png" alt="logo" />
        </div>

        <h4 class="page-ath-heading" *ngIf="!showStep2">
          Reset password<small style="font-size: 20px;">Enter your E-Mail to receive verification code to reset your
            password.
          </small>
        </h4>
        <form [formGroup]="resetPasswordForm">
          <fieldset formGroupName="questionGroup" *ngIf="showStep1">
            <div class="form-group">
              <input class="input-bordered" autocomplete="new-password" placeholder="Email" formControlName="email"
                (change)="changed()" />
              <!-- (input)="$event.target.value.length > 5 && searchData($event.target.value)"  -->

              <!-- <a name="" id="" class="btn btn-primary" href="#" role="button">Get</a> -->

              <div *ngIf="f.email.errors && (f.email.touched || f.email.dirty)" class="alert-box w-50">
                <div *ngIf="f.email.errors.required" class="ml-2 fs-14">
                  Email id is required.
                </div>
                <div *ngIf="f.email.errors.email" class="ml-2 fs-14">
                  Email must be valid.
                </div>
                <!-- <div *ngIf="f.email.errors.pattern" class="ml-2 fs-14">
                  Email must be valid.
                </div> -->
                <div *ngIf="f.email.valid" class="ml-2 fs-14">
                  valid.
                </div>
              </div>
            </div>

            <div style="margin-top: -10px; margin-bottom: 10px;">
              <ngx-invisible-recaptcha #captchaElem [siteKey]="this.siteKey" formControlName="captchaToken"
                (success)="handleSuccess($event)">
              </ngx-invisible-recaptcha>
            </div>

            <!-- <div class="form-group" style="margin-bottom: 20px;">

              <select
                class="form-control input-bordered form-control mb-4"
                formControlName="securityQuestion"
                name="question"
              >
                <option value="" selected disabled>Choose Question </option>
                <option [value]="item" *ngFor="let item of questionArray">{{
                  item
                }}</option>
              </select>
              <div
                *ngIf="
                  f.securityQuestion.errors &&
                  (f.securityQuestion.touched || f.securityQuestion.dirty) &&
                  !f.email.errors
                "
                class="alert-box w-50"
              >
                <div
                  *ngIf="f.securityQuestion.errors.required"
                  class="ml-2 fs-14"
                >
                  Security Question is required.
                </div>
              </div>
            </div> -->
            <!-- <div class="form-group">
              <input
                class="input-bordered"
                type="text"
                placeholder="Enter Answer"
                formControlName="securityAnswer"
                (keydown.enter)="handleKeyUp($event)"
              />
              <div
                *ngIf="
                  f.securityAnswer.errors &&
                  (f.securityAnswer.touched || f.securityAnswer.dirty) &&
                  !f.securityQuestion.errors
                "
                class="alert-box w-50"
              >
                <div
                  *ngIf="f.securityAnswer.errors.required"
                  class="ml-2 fs-14"
                >
                  Answer is required.
                </div>
              </div>
            </div> -->
            <!-- <div *ngIf="submittedQuestionGroup && questionGroupError" class="form-group">
              <div class="alert-box w-50">
                Incorrect details..!! Try again..!!
              </div>
            </div> -->
            <div *ngIf="showStep1Err == true" class="form-group">
              <div class="alert-box w-50">
                {{errorMsg}}
              </div>
            </div>

            <div class="text-center form-group">
              <button class="btn btn-md btn-primary w-100" [disabled]="f.email.errors" (click)="step2Func()">
                <span class="text-white">Continue</span>
              </button>
            </div>
            <div class="text-center form-group">
              <button class="btn btn-md btn-primary w-100" [routerLink]="['/login']"
                routerLinkActive="router-link-active">
                <a class="text-white">Back</a>
              </button>
            </div>
          </fieldset>
          <fieldset formGroupName="submitFormGroup" *ngIf="showStep2">
            <!-- text-white -->
            <p class="text-center alert-box1">
              Enter below the code we sent to your registered Email.
            </p>
            <div class="form-group">
              <input class="form-control mb-2" autocomplete="new-password" placeholder="Enter Code"
                formControlName="otp" [ngClass]="{
                  'not-valid': submittedResetPassword && f1.otp.errors
                }" />
              <div *ngIf="
                  submittedResetPassword && 
                  f1.otp.errors &&
                  (f1.otp.touched || f1.otp.dirty)
                " class="alert-box">
                <div *ngIf="f1.otp.errors.required" class="ml-2 fs-14">
                  Code is required.
                </div>
              </div>
            </div>
            <div class="form-group">
              <div class="input-group">
                <input [type]="EyeFlag ? 'text' : 'password'" autocomplete="new-password" class="form-control"
                  formControlName="newPassword" placeholder="New Password" [ngClass]="{
                    'is-invalid':
                      (submitted && f1.newPassword.errors) ||
                      (f1.newPassword.invalid &&
                        (f1.newPassword.dirty || f1.newPassword.touched))
                  }" />
                <div class="input-group-append">
                  <span class="input-group-text-reset">
                    <i class="fa" [ngClass]="{
                        'fa-eye-slash': !EyeFlag,
                        'fa-eye': EyeFlag
                      }" (click)="onClick()"></i>
                  </span>
                </div>
                <div *ngIf="
                    (submitted && f1.newPassword.errors) ||
                    (f1.newPassword.invalid &&
                      (f1.newPassword.dirty || f1.newPassword.touched))
                  " class="invalid-feedback">
                  <div *ngIf="f1.newPassword.errors.required">
                    New Password is required.
                  </div>
                  <div *ngIf="f1.newPassword.errors.pattern" class="ml-2 fs-16">
                    Your password must be between 10 and 16 characters in length and contain at least one combination of
                    letters (both lowercase and uppercase), numbers, and symbols.
                  </div>
                </div>
              </div>
            </div>

            <div class="form-group">
              <div class="input-group">
                <input [type]="repeatEyeFlag ? 'text' : 'password'" autocomplete="new-password" class="form-control"
                  formControlName="confirmPassword" placeholder="Confirm Password" [ngClass]="{
                    'is-invalid':
                      (submitted && f1.confirmPassword.errors) ||
                      (f1.confirmPassword.invalid &&
                        (f1.confirmPassword.dirty ||
                          f1.confirmPassword.touched))
                  }" />
                <div class="input-group-append">
                  <span class="input-group-text-reset">
                    <i class="fa" [ngClass]="{
                        'fa-eye-slash': !repeatEyeFlag,
                        'fa-eye': repeatEyeFlag
                      }" (click)="onClickRepeat()"></i>
                  </span>
                </div>
                <div *ngIf="
                    (submitted && f1.confirmPassword.errors) ||
                    (f1.confirmPassword.invalid &&
                      (f1.confirmPassword.dirty || f1.confirmPassword.touched))
                  " class="invalid-feedback">
                  <div *ngIf="f1.confirmPassword.errors.required">
                    Confirm Password is required.
                  </div>
                  <div *ngIf="f1.confirmPassword.errors.mustMatch" class="ml-2 fs-16">
                    Confirm password must match.
                  </div>
                </div>
              </div>
            </div>

            <br />
            <!-- <div *ngIf="!submittedResetPassword" class="alert-box">
                                Incorrect details..!! Try again..!!

                            </div> -->
            <!-- <p *ngIf="loginSuccessMessage" style="color: green;">**Login success!!</p>
                        <p *ngIf="loginErrorMessage" style="color: red;">**Incorrect credentials..!! Please try again..!!</p> -->
            <p></p>
            <div class="text-center form-group">
              <button class="btn btn-md btn-primary w-100" (click)="onSubmit()">
                <a class="text-white">Submit</a>
              </button>
            </div>
            <div class="text-center form-group">
              <button class="btn btn-md btn-primary w-100" (click)="back()">
                <a class="text-white">Back</a>
              </button>
            </div>
          </fieldset>
        </form>
        <!-- <div *ngIf="showStep3">
          <div>
            <h4>
              {{errorMsg}}
            </h4>
          </div>
          <a [routerLink]="['/login']" routerLinkActive="router-link-active"
            class="btn btn-primary w-100"><strong>Back</strong></a>
        </div> -->
        <!-- <div class="sap-text"><span>Or Sign In With</span></div>

                <ul class="row guttar-20px guttar-vr-20px">
                    <li class="col"><a href="#" class="btn btn-outline btn-dark btn-facebook btn-block"><em
                                class="fa fa-facebook"></em><span>Facebook</span></a></li>
                    <li class="col"><a href="#" class="btn btn-outline btn-dark btn-google btn-block"><em
                                class="fa fa-google"></em><span>Google</span></a></li>
                </ul> -->
        <div class="gaps-2x"></div>
        <div class="gaps-2x"></div>
      </div>

    </div>
    <div class="page-ath-gfx">
      <div class="login-l-lang-drop">
        <div id="google_translate_element" style="float:left; padding-left:15px"></div>

        <div class="dropdown lang-dropdown" style="float: inline-end;">
          <button class="btn btn-secondary dropdown-toggle" type="button" style="background: none;"
            id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
            <img id="lang-img" src="assets/images/eng.svg" alt=" Flag" /> <span style="margin-left: 6px;"
              id="langswitch">English</span>
          </button>
          <ul class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton1">
            <li><a class="dropdown-item" id="english" style="display: none;" onclick="changelang('English','assets/images/eng.svg','en','en');
              return false;" href="#"><img src="assets/images/eng.svg" class="lang-img1" alt=" Flag" />
                <span style="margin-left: 6px;"> English </span> </a></li>
            <li><a class="dropdown-item" id="turkish"
                onclick="changelang('Turkish','assets/images/tr.svg','en','tr');return false;" href="#"><img
                  class="lang-img1" src="assets/images/tr.svg" alt=" Flag" />
                Turkish</a></li>
            <li><a class="dropdown-item" id="portuguese"
                onclick="changelang('Portuguese','assets/images/pt.svg','en','pt');return false;" href="#"><img
                  class="lang-img1" src="assets/images/pt.svg" alt=" Flag" />
                Portuguese</a></li>

          </ul>
        </div>
      </div>
      <div class="w-100 d-flex justify-content-center">
        <div class="col-md-8 col-xl-5">
          <!-- <img src="assets/images/signin-img.svg"/> -->
        </div>
      </div>
    </div>
  </div>
</div>