<body class="page-ath">
  <div class="page-ath-wrap lr-main">
    <div class="page-ath-form mt-2">
      
      <div *ngIf="!showPopup">
        <h2 class="page-ath-heading loginTitle">
          Sign up <small>Create New WLTHX Token Account</small>
        </h2>
        <form [formGroup]="registerForm" (submit)="submitRegister()">
          <div class="form-group">
            <input class="input-bordered" name="firstName" type="text" formControlName="firstName"
              placeholder="First Name" />
          </div>

          <div *ngIf="(submitted && f.firstName.errors) || (f.firstName.invalid && (f.firstName.dirty || f.firstName.touched))">
            <div class="alert alert-danger error-messag" *ngIf="f.firstName.errors.required">
              First Name is required.
            </div>
            <!-- <div *ngIf="f.firstName.errors.minlength || f.firstName.errors.maxlength" class="ml-2 fs-16 alert alert-danger error-messag">
          First Name must have 5 - 10 Charcters
        </div>  -->
          </div>
          <div class="form-group">
            <input class="input-bordered" type="text" formControlName="lastName" placeholder="Last Name" />
          </div>
          <div *ngIf="
          (submitted && f.lastName.errors) ||
          (f.lastName.invalid &&
            (f.lastName.dirty || f.lastName.touched))
        ">

            <div class="alert alert-danger error-messag" *ngIf="f.lastName.errors.required">
              Last Name is required.
            </div>

          </div>
          <div class="form-group">
            <input class="input-bordered" type="email" formControlName="email" placeholder="Email" name="email" />
          </div>

          <div *ngIf="
          (submitted && f.email.errors) ||
          (f.email.invalid &&
            (f.email.dirty || f.email.touched))
        ">

            <div class="alert alert-danger error-messag" *ngIf="f.email.errors.required">
              Email is required.
            </div>

            <div class="alert alert-danger error-messag" *ngIf="f.email.errors.pattern">
              Enter Valid Email Address
            </div>

          </div>

          <div class="form-group position-relative">
            <input class="input-bordered" [type]="EyeFlag ? 'text' : 'password'" name="password"
              formControlName="password" placeholder="Password" />
            <i class="fa pass" [ngClass]="{
                'fa-eye-slash': !EyeFlag,
                'fa-eye': EyeFlag
              }" (click)="onClick()"></i>
          </div>

          <div *ngIf="
          (submitted && f.password.errors) ||
          (f.password.invalid &&
            (f.password.dirty || f.password.touched))
        ">

            <div class="alert alert-danger error-messag" *ngIf="f.password.errors.required">
              Password is required.
            </div>
            <div *ngIf="f.password.errors.pattern" class="ml-2 fs-16 alert alert-danger error-messag">
              Your password must be between 10 and 16 characters in length and contain at least one combination of
              letters (both lowercase and uppercase), numbers, and symbols.
            </div>
          </div>

          <div class="form-group form position-relative">
            <input class="input-bordered" [type]="repeatEyeFlag ? 'text' : 'password'" name="confirmPassword"
              formControlName="confirmPassword" placeholder="Re-type Password" [ngClass]="{
                'not-valid': submitted && f.confirmPassword.errors
              }" />
            <i class="fa pass" [ngClass]="{
                'fa-eye-slash': !repeatEyeFlag,
                'fa-eye': repeatEyeFlag
              }" (click)="onClickRepeat()"></i>
          </div>

          <div *ngIf="
          (submitted && f.confirmPassword.errors) ||
          (f.email.invalid &&
            (f.email.dirty || f.email.touched))
        ">

            <div class="alert alert-danger error-messag" *ngIf="f.confirmPassword.errors">
              Password and Confirm Password Must Match
            </div>


          </div>

          <div class="form-group" style="margin-bottom: 20px;">
            <select name="country" name="country" class="form-control input-bordered mb-4" placeholder="country"
              formControlName="country" [ngClass]="{ 'not-valid': submitted && f.country.errors }">
              <option value="" selected disabled>Select country</option>
              <option *ngFor="let item of country" [value]="item.value">{{
                item.name
                }}</option>
            </select>
          </div>

          <div *ngIf="
          (submitted && f.country.errors) ||
          (f.country.invalid &&
            (f.country.dirty || f.country.touched))
        ">

            <div class="alert alert-danger error-messag" *ngIf="f.country.errors.required">
              Please Choose Country
            </div>


          </div>

          <div class="form-group" *ngIf="visible == true">
            <input class="input-bordered" type="text" formControlName="referalCode" readonly
              placeholder="Referal Code" />
          </div>

          <div class="form-group">
            <div class="input-item text-left">
              <input class="input-checkbox input-checkbox-md" id="term-condition" type="checkbox"
                formControlName="checkBox" name="checkBox" />
              <label for="term-condition">I agree to WLTHX's
                <a href="https://www.wlthx.com/Terms-and-Conditions-WLTHX.pdf" target="_blank"> Terms & Conditions</a>

              </label>


            </div>
          </div>

          <div *ngIf="
          (submitted && f.checkBox.errors) ||
          (f.checkBox.invalid &&
            (f.checkBox.dirty || f.checkBox.touched))
        ">

            <div class="alert alert-danger error-messag" *ngIf="f.checkBox.errors.required">
              Please Agree the Terms & Conditions
            </div>


          </div>




          <div class="form-group">
            <button class="btn btn-primary btn-block" type="submit">
              <a class="text-white" style="text-decoration: none;">
                Create Account
              </a>
            </button>
          </div>
        </form>
      </div>

      <div *ngIf="showPopup">
        <h2 class="page-ath-heading loginTitle">
          Sign up
          <small>Enter below the code we sent to your registered Email.</small>
        </h2>


        <div class="row justify-content-center form-group" style="margin: auto; width: 92%;">
          <input [(ngModel)]="otpValue" class="form-control" name="otp" value="" placeholder="Enter Code"
            (keydown.enter)="handleKeyUp($event)" />
          <small>Note : CODE Will Expire in 10 minutes. If Expired Try Registration with same Email once again </small>
        </div>



        <div class="h-25 mt-4 pt-2" style="margin: auto; width: 100%;">
          <div style="display: flex; justify-content: space-between;">
            <div class="col-sm">
              <!--  type="button" class="btn btn-md btn-primary w-100" -->
              <button type="button" class="btn btn-md w-100" style="background-color: #f35b59; color: white;"
                (click)="resendOtp()">
                Resend Code
              </button>
            </div>
            <div class="col-sm">
              <button type="button" style="color: white;" class="btn btn-md w-100" style="
                  background: #fdb144;
                  color: white;
                  border-color: #fdb144;
                " (click)="sendModal()">
                Confirm
              </button>
            </div>
          </div>
          <div class="col-sm mt-4">
            <!-- <button type="button" class="btn btn-lg btn-block fs-14" (click)="hideModal()">
              Cancel
            </button> -->
            <button type="button" style="background-color: #383a4f; color: white; margin-top: 10px;"
              class="btn btn-lg btn-block fs-14" (click)="hideModal()">
              Cancel
            </button>
          </div>
        </div>
      </div>
      <!-- <ul class="row guttar-20px guttar-vr-20px">
        <li class="col mt-2"><a href="#" class="btn btn-outline btn-dark btn-facebook btn-block"><em
              class="fab fa-facebook-f"></em><span>Facebook</span></a></li>
        <li class="col mt-2"><a href="#" class="btn btn-outline btn-dark btn-google btn-block"><em
              class="fab fa-google"></em><span>Google</span></a></li>
      </ul> -->
      <div class="gaps-2x"></div>
      <div class="form-note text-center">
        Already have an account ?
        <a [routerLink]="['/login']" routerLinkActive="router-link-active" class="primaryColor">
          <strong>Sign in instead</strong></a>
      </div>

      <div class="form-note text-center pt-3">

        <a [routerLink]="['/login']" routerLinkActive="router-link-active" class="primaryColor">
          <strong>How did you hear of us?</strong></a>
      </div>
    </div>
    <div class="page-ath-gfx">
      <div class="login-l-lang-drop">
        <div id="google_translate_element" style="float:left; padding-left:15px"></div>
        <div class="dropdown lang-dropdown" style="float: inline-end;">
          <button class="btn btn-secondary dropdown-toggle" type="button" style="background: none;"
            id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
            <img id="lang-img" src="assets/images/eng.svg" alt=" Flag" /> <span style="margin-left: 6px;"
              id="langswitch">English</span>
          </button>
          <ul class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton1">
            <li><a class="dropdown-item" id="english" style="display: none;" onclick="changelang('English','assets/images/eng.svg','en','en');
                    return false;" href="#"><img src="assets/images/eng.svg" class="lang-img1" alt=" Flag" />
                <span style="margin-left: 6px;"> English </span> </a></li>
            <li><a class="dropdown-item" id="turkish"
                onclick="changelang('Turkish','assets/images/tr.svg','en','tr');return false;" href="#"><img
                  class="lang-img1" src="assets/images/tr.svg" alt=" Flag" />
                Turkish</a></li>
            <li><a class="dropdown-item" id="portuguese"
                onclick="changelang('Portuguese','assets/images/pt.svg','en','pt');return false;" href="#"><img
                  class="lang-img1" src="assets/images/pt.svg" alt=" Flag" />
                Portuguese</a></li>
          </ul>
        </div>
      </div>
    </div>
  </div>

  <!-- JavaScript (include all script here) -->
  <script src="assets/js/jquery.bundle.js"></script>
  <script src="assets/js/script.js"></script>
</body>