import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  Input,
  Output,
  EventEmitter,
  ViewEncapsulation,
  NgModuleRef,
} from "@angular/core";
import {
  NgbModal,
  ModalDismissReasons,
  NgbActiveModal,
} from "@ng-bootstrap/ng-bootstrap";
import { ActivatedRoute, Params, Router } from "@angular/router";
import {
  FormGroup,
  FormBuilder,
  Validators,
  CheckboxRequiredValidator,
  FormControl,
  ValidatorFn,
  AbstractControl,
  ValidationErrors,
} from "@angular/forms";
import * as CryptoJS from "crypto-js";
import { ApiService } from "../../../../core/services/api.service";
import { first } from "rxjs/operators";
import { MustMatch } from "./mustMatch";
import { ModalPopupComponent } from "src/app/shared/components/modal-popup/modal-popup.component";
import { PasswordStrengthValidator } from "./password-strength.validators";
import { ToastrService } from "ngx-toastr";
// import * as $ from 'jquery';
declare var $: any;

@Component({
  selector: "verifyOtpModalContent",
  template: `
    <div class="modal-header">
      <!-- <h5 class="modal-title" style="color:blue">Please Check</h5> -->
      <button
        type="button"
        class="close"
        aria-label="Close"
        (click)="ngbActiveModal.dismiss('Cross click')"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <p class="text-center" *ngIf="email">
        Email must be valid.
      </p>
      <p class="text-center" *ngIf="password">
        Your password length should be between 10 and 16 Characters. Must
        contain at least one combination of letters and numbers.
      </p>
      <p class="text-center" *ngIf="confirmPassword">
        Password and Confirm Password should match.
      </p>

      <div class="row h-25 mt-4 pt-2">
        <!-- background-image: linear-gradient(90deg,#876c1d,#9da063,#7c6526);margin-top: 10px; -->
        <!-- btn btn-lg btn-block bttn gradient-button-red fs-14 -->
        <div class="col-sm">
          <button
            type="button"
            style="background-color: #383A4F; color: white; margin-top: 10px;"
            class="btn btn-lg btn-block fs-14"
            (click)="ngbActiveModal.close('Close click')"
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  `,
  styles: [],
})
export class RegErrorMsgModalContent {
  @Input() email;
  @Input() password;
  @Input() confirmPassword;
  otpValue: any;

  otpMessage = true;
  constructor(public ngbActiveModal: NgbActiveModal) {}
}

@Component({
  selector: "app-register",
  templateUrl: "./register.component.html",
  styleUrls: ["./register.component.scss"],
  // encapsulation: ViewEncapsulation.None
})
export class RegisterComponent implements OnInit {
  title = "appBootstrap";
  showPopup = false;
  otpValue;
  otpError = false;
  @ViewChild("myModal") myModal: ElementRef;
  closeResult: string;
  registerForm: FormGroup;
  loading = false;
  submitted = false;
  ApiService: ApiService;
  questions: any;
  security = false;
  EyeFlag: boolean;
  repeatEyeFlag: boolean;
  questionArray = [];
  otpMessage;
  getEmail;
  country = [
    { value: "AF", code3: "AFG", name: "Afghanistan", number: "004" },
    { value: "AL", code3: "ALB", name: "Albania", number: "008" },
    { value: "DZ", code3: "DZA", name: "Algeria", number: "012" },
    { value: "AS", code3: "ASM", name: "American Samoa", number: "016" },
    { value: "AD", code3: "AND", name: "Andorra", number: "020" },
    { value: "AO", code3: "AGO", name: "Angola", number: "024" },
    { value: "AI", code3: "AIA", name: "Anguilla", number: "660" },
    { value: "AQ", code3: "ATA", name: "Antarctica", number: "010" },
    {
      value: "AG",
      code3: "ATG",
      name: "Antigua and Barbuda",
      number: "028",
    },
    { value: "AR", code3: "ARG", name: "Argentina", number: "032" },
    { value: "AM", code3: "ARM", name: "Armenia", number: "051" },
    { value: "AW", code3: "ABW", name: "Aruba", number: "533" },
    { value: "AU", code3: "AUS", name: "Australia", number: "036" },
    { value: "AT", code3: "AUT", name: "Austria", number: "040" },
    { value: "AZ", code3: "AZE", name: "Azerbaijan", number: "031" },
    { value: "BS", code3: "BHS", name: "Bahamas (the)", number: "044" },
    { value: "BH", code3: "BHR", name: "Bahrain", number: "048" },
    { value: "BD", code3: "BGD", name: "Bangladesh", number: "050" },
    { value: "BB", code3: "BRB", name: "Barbados", number: "052" },
    { value: "BY", code3: "BLR", name: "Belarus", number: "112" },
    { value: "BE", code3: "BEL", name: "Belgium", number: "056" },
    { value: "BZ", code3: "BLZ", name: "Belize", number: "084" },
    { value: "BJ", code3: "BEN", name: "Benin", number: "204" },
    { value: "BM", code3: "BMU", name: "Bermuda", number: "060" },
    { value: "BT", code3: "BTN", name: "Bhutan", number: "064" },
    {
      value: "BO",
      code3: "BOL",
      name: "Bolivia (Plurinational State of)",
      number: "068",
    },
    {
      value: "BQ",
      code3: "BES",
      name: "Bonaire, Sint Eustatius and Saba",
      number: "535",
    },
    {
      value: "BA",
      code3: "BIH",
      name: "Bosnia and Herzegovina",
      number: "070",
    },
    { value: "BW", code3: "BWA", name: "Botswana", number: "072" },
    { value: "BV", code3: "BVT", name: "Bouvet Island", number: "074" },
    { value: "BR", code3: "BRA", name: "Brazil", number: "076" },
    {
      value: "IO",
      code3: "IOT",
      name: "British Indian Ocean Territory (the)",
      number: "086",
    },
    { value: "BN", code3: "BRN", name: "Brunei Darussalam", number: "096" },
    { value: "BG", code3: "BGR", name: "Bulgaria", number: "100" },
    { value: "BF", code3: "BFA", name: "Burkina Faso", number: "854" },
    { value: "BI", code3: "BDI", name: "Burundi", number: "108" },
    { value: "CV", code3: "CPV", name: "Cabo Verde", number: "132" },
    { value: "KH", code3: "KHM", name: "Cambodia", number: "116" },
    { value: "CM", code3: "CMR", name: "Cameroon", number: "120" },
    { value: "CA", code3: "CAN", name: "Canada", number: "124" },
    {
      value: "KY",
      code3: "CYM",
      name: "Cayman Islands (the)",
      number: "136",
    },
    {
      value: "CF",
      code3: "CAF",
      name: "Central African Republic (the)",
      number: "140",
    },
    { value: "TD", code3: "TCD", name: "Chad", number: "148" },
    { value: "CL", code3: "CHL", name: "Chile", number: "152" },
    { value: "CN", code3: "CHN", name: "China", number: "156" },
    { value: "CX", code3: "CXR", name: "Christmas Island", number: "162" },
    {
      value: "CC",
      code3: "CCK",
      name: "Cocos (Keeling) Islands (the)",
      number: "166",
    },
    { value: "CO", code3: "COL", name: "Colombia", number: "170" },
    { value: "KM", code3: "COM", name: "Comoros (the)", number: "174" },
    {
      value: "CD",
      code3: "COD",
      name: "Congo (the Democratic Republic of the)",
      number: "180",
    },
    { value: "CG", code3: "COG", name: "Congo (the)", number: "178" },
    {
      value: "CK",
      code3: "COK",
      name: "Cook Islands (the)",
      number: "184",
    },
    { value: "CR", code3: "CRI", name: "Costa Rica", number: "188" },
    { value: "HR", code3: "HRV", name: "Croatia", number: "191" },
    { value: "CU", code3: "CUB", name: "Cuba", number: "192" },
    { value: "CW", code3: "CUW", name: "Curaçao", number: "531" },
    { value: "CY", code3: "CYP", name: "Cyprus", number: "196" },
    { value: "CZ", code3: "CZE", name: "Czechia", number: "203" },
    { value: "CI", code3: "CIV", name: "Côte d Ivoire", number: "384" },
    { value: "DK", code3: "DNK", name: "Denmark", number: "208" },
    { value: "DJ", code3: "DJI", name: "Djibouti", number: "262" },
    { value: "DM", code3: "DMA", name: "Dominica", number: "212" },
    {
      value: "DO",
      code3: "DOM",
      name: "Dominican Republic (the)",
      number: "214",
    },
    { value: "EC", code3: "ECU", name: "Ecuador", number: "218" },
    { value: "EG", code3: "EGY", name: "Egypt", number: "818" },
    { value: "SV", code3: "SLV", name: "El Salvador", number: "222" },
    { value: "GQ", code3: "GNQ", name: "Equatorial Guinea", number: "226" },
    { value: "ER", code3: "ERI", name: "Eritrea", number: "232" },
    { value: "EE", code3: "EST", name: "Estonia", number: "233" },
    { value: "SZ", code3: "SWZ", name: "Eswatini", number: "748" },
    { value: "ET", code3: "ETH", name: "Ethiopia", number: "231" },
    {
      value: "FK",
      code3: "FLK",
      name: "Falkland Islands (the) [Malvinas]",
      number: "238",
    },
    {
      value: "FO",
      code3: "FRO",
      name: "Faroe Islands (the)",
      number: "234",
    },
    { value: "FJ", code3: "FJI", name: "Fiji", number: "242" },
    { value: "FI", code3: "FIN", name: "Finland", number: "246" },
    { value: "FR", code3: "FRA", name: "France", number: "250" },
    { value: "GF", code3: "GUF", name: "French Guiana", number: "254" },
    { value: "PF", code3: "PYF", name: "French Polynesia", number: "258" },
    {
      value: "TF",
      code3: "ATF",
      name: "French Southern Territories (the)",
      number: "260",
    },
    { value: "GA", code3: "GAB", name: "Gabon", number: "266" },
    { value: "GM", code3: "GMB", name: "Gambia (the)", number: "270" },
    { value: "GE", code3: "GEO", name: "Georgia", number: "268" },
    { value: "DE", code3: "DEU", name: "Germany", number: "276" },
    { value: "GH", code3: "GHA", name: "Ghana", number: "288" },
    { value: "GI", code3: "GIB", name: "Gibraltar", number: "292" },
    { value: "GR", code3: "GRC", name: "Greece", number: "300" },
    { value: "GL", code3: "GRL", name: "Greenland", number: "304" },
    { value: "GD", code3: "GRD", name: "Grenada", number: "308" },
    { value: "GP", code3: "GLP", name: "Guadeloupe", number: "312" },
    { value: "GU", code3: "GUM", name: "Guam", number: "316" },
    { value: "GT", code3: "GTM", name: "Guatemala", number: "320" },
    { value: "GG", code3: "GGY", name: "Guernsey", number: "831" },
    { value: "GN", code3: "GIN", name: "Guinea", number: "324" },
    { value: "GW", code3: "GNB", name: "Guinea-Bissau", number: "624" },
    { value: "GY", code3: "GUY", name: "Guyana", number: "328" },
    { value: "HT", code3: "HTI", name: "Haiti", number: "332" },
    {
      value: "HM",
      code3: "HMD",
      name: "Heard Island and McDonald Islands",
      number: "334",
    },
    { value: "VA", code3: "VAT", name: "Holy See (the)", number: "336" },
    { value: "HN", code3: "HND", name: "Honduras", number: "340" },
    { value: "HK", code3: "HKG", name: "Hong Kong", number: "344" },
    { value: "HU", code3: "HUN", name: "Hungary", number: "348" },
    { value: "IS", code3: "ISL", name: "Iceland", number: "352" },
    { value: "IN", code3: "IND", name: "India", number: "356" },
    { value: "ID", code3: "IDN", name: "Indonesia", number: "360" },
    {
      value: "IR",
      code3: "IRN",
      name: "Iran (Islamic Republic of)",
      number: "364",
    },
    { value: "IQ", code3: "IRQ", name: "Iraq", number: "368" },
    { value: "IE", code3: "IRL", name: "Ireland", number: "372" },
    { value: "IM", code3: "IMN", name: "Isle of Man", number: "833" },
    { value: "IL", code3: "ISR", name: "Israel", number: "376" },
    { value: "IT", code3: "ITA", name: "Italy", number: "380" },
    { value: "JM", code3: "JAM", name: "Jamaica", number: "388" },
    { value: "JP", code3: "JPN", name: "Japan", number: "392" },
    { value: "JE", code3: "JEY", name: "Jersey", number: "832" },
    { value: "JO", code3: "JOR", name: "Jordan", number: "400" },
    { value: "KZ", code3: "KAZ", name: "Kazakhstan", number: "398" },
    { value: "KE", code3: "KEN", name: "Kenya", number: "404" },
    { value: "KI", code3: "KIR", name: "Kiribati", number: "296" },
    {
      value: "KP",
      code3: "PRK",
      name: "Korea (the Democratic People Republic of)",
      number: "408",
    },
    {
      value: "KR",
      code3: "KOR",
      name: "Korea (the Republic of)",
      number: "410",
    },
    { value: "KW", code3: "KWT", name: "Kuwait", number: "414" },
    { value: "KG", code3: "KGZ", name: "Kyrgyzstan", number: "417" },
    {
      value: "LA",
      code3: "LAO",
      name: "Lao Peoples Democratic Republic (the)",
      number: "418",
    },
    { value: "LV", code3: "LVA", name: "Latvia", number: "428" },
    { value: "LB", code3: "LBN", name: "Lebanon", number: "422" },
    { value: "LS", code3: "LSO", name: "Lesotho", number: "426" },
    { value: "LR", code3: "LBR", name: "Liberia", number: "430" },
    { value: "LY", code3: "LBY", name: "Libya", number: "434" },
    { value: "LI", code3: "LIE", name: "Liechtenstein", number: "438" },
    { value: "LT", code3: "LTU", name: "Lithuania", number: "440" },
    { value: "LU", code3: "LUX", name: "Luxembourg", number: "442" },
    { value: "MO", code3: "MAC", name: "Macao", number: "446" },
    { value: "MG", code3: "MDG", name: "Madagascar", number: "450" },
    { value: "MW", code3: "MWI", name: "Malawi", number: "454" },
    { value: "MY", code3: "MYS", name: "Malaysia", number: "458" },
    { value: "MV", code3: "MDV", name: "Maldives", number: "462" },
    { value: "ML", code3: "MLI", name: "Mali", number: "466" },
    { value: "MT", code3: "MLT", name: "Malta", number: "470" },
    {
      value: "MH",
      code3: "MHL",
      name: "Marshall Islands (the)",
      number: "584",
    },
    { value: "MQ", code3: "MTQ", name: "Martinique", number: "474" },
    { value: "MR", code3: "MRT", name: "Mauritania", number: "478" },
    { value: "MU", code3: "MUS", name: "Mauritius", number: "480" },
    { value: "YT", code3: "MYT", name: "Mayotte", number: "175" },
    { value: "MX", code3: "MEX", name: "Mexico", number: "484" },
    {
      value: "FM",
      code3: "FSM",
      name: "Micronesia (Federated States of)",
      number: "583",
    },
    {
      value: "MD",
      code3: "MDA",
      name: "Moldova (the Republic of)",
      number: "498",
    },
    { value: "MC", code3: "MCO", name: "Monaco", number: "492" },
    { value: "MN", code3: "MNG", name: "Mongolia", number: "496" },
    { value: "ME", code3: "MNE", name: "Montenegro", number: "499" },
    { value: "MS", code3: "MSR", name: "Montserrat", number: "500" },
    { value: "MA", code3: "MAR", name: "Morocco", number: "504" },
    { value: "MZ", code3: "MOZ", name: "Mozambique", number: "508" },
    { value: "MM", code3: "MMR", name: "Myanmar", number: "104" },
    { value: "NA", code3: "NAM", name: "Namibia", number: "516" },
    { value: "NR", code3: "NRU", name: "Nauru", number: "520" },
    { value: "NP", code3: "NPL", name: "Nepal", number: "524" },
    { value: "NL", code3: "NLD", name: "Netherlands (the)", number: "528" },
    { value: "NC", code3: "NCL", name: "New Caledonia", number: "540" },
    { value: "NZ", code3: "NZL", name: "New Zealand", number: "554" },
    { value: "NI", code3: "NIC", name: "Nicaragua", number: "558" },
    { value: "NE", code3: "NER", name: "Niger (the)", number: "562" },
    { value: "NG", code3: "NGA", name: "Nigeria", number: "566" },
    { value: "NU", code3: "NIU", name: "Niue", number: "570" },
    { value: "NF", code3: "NFK", name: "Norfolk Island", number: "574" },
    {
      value: "MP",
      code3: "MNP",
      name: "Northern Mariana Islands (the)",
      number: "580",
    },
    { value: "NO", code3: "NOR", name: "Norway", number: "578" },
    { value: "OM", code3: "OMN", name: "Oman", number: "512" },
    { value: "PK", code3: "PAK", name: "Pakistan", number: "586" },
    { value: "PW", code3: "PLW", name: "Palau", number: "585" },
    {
      value: "PS",
      code3: "PSE",
      name: "Palestine, State of",
      number: "275",
    },
    { value: "PA", code3: "PAN", name: "Panama", number: "591" },
    { value: "PG", code3: "PNG", name: "Papua New Guinea", number: "598" },
    { value: "PY", code3: "PRY", name: "Paraguay", number: "600" },
    { value: "PE", code3: "PER", name: "Peru", number: "604" },
    { value: "PH", code3: "PHL", name: "Philippines (the)", number: "608" },
    { value: "PN", code3: "PCN", name: "Pitcairn", number: "612" },
    { value: "PL", code3: "POL", name: "Poland", number: "616" },
    { value: "PT", code3: "PRT", name: "Portugal", number: "620" },
    { value: "PR", code3: "PRI", name: "Puerto Rico", number: "630" },
    { value: "QA", code3: "QAT", name: "Qatar", number: "634" },
    {
      value: "MK",
      code3: "MKD",
      name: "Republic of North Macedonia",
      number: "807",
    },
    { value: "RO", code3: "ROU", name: "Romania", number: "642" },
    {
      value: "RU",
      code3: "RUS",
      name: "Russian Federation (the)",
      number: "643",
    },
    { value: "RW", code3: "RWA", name: "Rwanda", number: "646" },
    { value: "RE", code3: "REU", name: "Réunion", number: "638" },
    { value: "BL", code3: "BLM", name: "Saint Barthélemy", number: "652" },
    {
      value: "SH",
      code3: "SHN",
      name: "Saint Helena, Ascension and Tristan da Cunha",
      number: "654",
    },
    {
      value: "KN",
      code3: "KNA",
      name: "Saint Kitts and Nevis",
      number: "659",
    },
    { value: "LC", code3: "LCA", name: "Saint Lucia", number: "662" },
    {
      value: "MF",
      code3: "MAF",
      name: "Saint Martin (French part)",
      number: "663",
    },
    {
      value: "PM",
      code3: "SPM",
      name: "Saint Pierre and Miquelon",
      number: "666",
    },
    {
      value: "VC",
      code3: "VCT",
      name: "Saint Vincent and the Grenadines",
      number: "670",
    },
    { value: "WS", code3: "WSM", name: "Samoa", number: "882" },
    { value: "SM", code3: "SMR", name: "San Marino", number: "674" },
    {
      value: "ST",
      code3: "STP",
      name: "Sao Tome and Principe",
      number: "678",
    },
    { value: "SA", code3: "SAU", name: "Saudi Arabia", number: "682" },
    { value: "SN", code3: "SEN", name: "Senegal", number: "686" },
    { value: "RS", code3: "SRB", name: "Serbia", number: "688" },
    { value: "SC", code3: "SYC", name: "Seychelles", number: "690" },
    { value: "SL", code3: "SLE", name: "Sierra Leone", number: "694" },
    { value: "SG", code3: "SGP", name: "Singapore", number: "702" },
    {
      value: "SX",
      code3: "SXM",
      name: "Sint Maarten (Dutch part)",
      number: "534",
    },
    { value: "SK", code3: "SVK", name: "Slovakia", number: "703" },
    { value: "SI", code3: "SVN", name: "Slovenia", number: "705" },
    { value: "SB", code3: "SLB", name: "Solomon Islands", number: "090" },
    { value: "SO", code3: "SOM", name: "Somalia", number: "706" },
    { value: "ZA", code3: "ZAF", name: "South Africa", number: "710" },
    {
      value: "GS",
      code3: "SGS",
      name: "South Georgia and the South Sandwich Islands",
      number: "239",
    },
    { value: "SS", code3: "SSD", name: "South Sudan", number: "728" },
    { value: "ES", code3: "ESP", name: "Spain", number: "724" },
    { value: "LK", code3: "LKA", name: "Sri Lanka", number: "144" },
    { value: "SD", code3: "SDN", name: "Sudan (the)", number: "729" },
    { value: "SR", code3: "SUR", name: "Suriname", number: "740" },
    {
      value: "SJ",
      code3: "SJM",
      name: "Svalbard and Jan Mayen",
      number: "744",
    },
    { value: "SE", code3: "SWE", name: "Sweden", number: "752" },
    { value: "CH", code3: "CHE", name: "Switzerland", number: "756" },
    {
      value: "SY",
      code3: "SYR",
      name: "Syrian Arab Republic",
      number: "760",
    },
    {
      value: "TW",
      code3: "TWN",
      name: "Taiwan (Province of China)",
      number: "158",
    },
    { value: "TJ", code3: "TJK", name: "Tajikistan", number: "762" },
    {
      value: "TZ",
      code3: "TZA",
      name: "Tanzania, United Republic of",
      number: "834",
    },
    { value: "TH", code3: "THA", name: "Thailand", number: "764" },
    { value: "TL", code3: "TLS", name: "Timor-Leste", number: "626" },
    { value: "TG", code3: "TGO", name: "Togo", number: "768" },
    { value: "TK", code3: "TKL", name: "Tokelau", number: "772" },
    { value: "TO", code3: "TON", name: "Tonga", number: "776" },
    {
      value: "TT",
      code3: "TTO",
      name: "Trinidad and Tobago",
      number: "780",
    },
    { value: "TN", code3: "TUN", name: "Tunisia", number: "788" },
    { value: "TR", code3: "TUR", name: "Turkey", number: "792" },
    { value: "TM", code3: "TKM", name: "Turkmenistan", number: "795" },
    {
      value: "TC",
      code3: "TCA",
      name: "Turks and Caicos Islands (the)",
      number: "796",
    },
    { value: "TV", code3: "TUV", name: "Tuvalu", number: "798" },
    { value: "UG", code3: "UGA", name: "Uganda", number: "800" },
    { value: "UA", code3: "UKR", name: "Ukraine", number: "804" },
    {
      value: "AE",
      code3: "ARE",
      name: "United Arab Emirates (the)",
      number: "784",
    },
    {
      value: "GB",
      code3: "GBR",
      name: "United Kingdom of Great Britain and Northern Ireland (the)",
      number: "826",
    },
    {
      value: "UM",
      code3: "UMI",
      name: "United States Minor Outlying Islands (the)",
      number: "581",
    },
    {
      value: "US",
      code3: "USA",
      name: "United States of America (the)",
      number: "840",
    },
    { value: "UY", code3: "URY", name: "Uruguay", number: "858" },
    { value: "UZ", code3: "UZB", name: "Uzbekistan", number: "860" },
    { value: "VU", code3: "VUT", name: "Vanuatu", number: "548" },
    {
      value: "VE",
      code3: "VEN",
      name: "Venezuela (Bolivarian Republic of)",
      number: "862",
    },
    { value: "VN", code3: "VNM", name: "Viet Nam", number: "704" },
    {
      value: "VG",
      code3: "VGB",
      name: "Virgin Islands (British)",
      number: "092",
    },
    {
      value: "VI",
      code3: "VIR",
      name: "Virgin Islands (U.S.)",
      number: "850",
    },
    { value: "WF", code3: "WLF", name: "Wallis and Futuna", number: "876" },
    { value: "EH", code3: "ESH", name: "Western Sahara", number: "732" },
    { value: "YE", code3: "YEM", name: "Yemen", number: "887" },
    { value: "ZM", code3: "ZMB", name: "Zambia", number: "894" },
    { value: "ZW", code3: "ZWE", name: "Zimbabwe", number: "716" },
    { value: "AX", code3: "ALA", name: "Åland Islands", number: "248" },
  ];
  genderList = [
    { name: "Male", value: "male" },
    { name: "Female", value: "female" },
  ];
  emailAlreadyTekenError = false;
  // @Input("email") email;
  // @Output() eventChanged = new EventEmitter();
  @ViewChild(ModalPopupComponent) modalPopup;

  visible = false;
  referalCode: any;
  test: boolean;
  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private apiService: ApiService,
    private ngbModal: NgbModal,
    private activatedRoute: ActivatedRoute,
    private toastrService: ToastrService
  ) {
    
    // this.RegisterForm();

    // "(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9()!@#$%^&*]{8,16}"
    this.registerForm = this.formBuilder.group(
      {
        firstName: new FormControl("", [
          Validators.required,
         // Validators.minLength(5),
         // Validators.maxLength(10),
        ]),
        lastName: new FormControl("", Validators.required),
        email: new FormControl("", [
          Validators.required,
          Validators.pattern("^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$"),
        ]),

        password: new FormControl("", [
          Validators.required,
          Validators.pattern("(?=.*[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[$!%?_@.#&+-]).\\S{9,15}$")
        ]),
        confirmPassword: new FormControl("", Validators.required),
        country: new FormControl("", Validators.required),
        referalCode: new FormControl(
          { value: "", disabled: true },
          Validators.required
        ),
        checkBox: new FormControl(false, Validators.requiredTrue),
      },
      {
        validators: (control) => {
          if (control.value.password !== control.value.confirmPassword) {
            control.get("confirmPassword").setErrors({ notSame: true });
          }
          return null;
        },
      }
    );
  }

  ngOnInit() {
    this.activatedRoute.queryParamMap.subscribe((queryParams) => {
      this.referalCode = queryParams.get("referal");
      if (this.referalCode !== null && this.referalCode !== "") {
        this.visible = true;
        this.registerForm.controls["referalCode"].enable();
        this.registerForm.get("referalCode").setValue(this.referalCode);
      }
    });
  }

  set(keys, value) {
    var key = CryptoJS.enc.Utf8.parse(keys);
    var iv = CryptoJS.enc.Utf8.parse(keys);
    var encrypted = CryptoJS.AES.encrypt(
      CryptoJS.enc.Utf8.parse(value.toString()),
      key,
      {
        keySize: 128 / 8,
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
      }
    );
    return encrypted.toString();
  }

  RegisterForm() {
    this.registerForm = this.formBuilder.group(
      {
        firstName: [
          "",
          Validators.required,
          Validators.minLength(5),
          Validators.maxLength(10),
        ],
        lastName: ["", [Validators.required]],
        email: [
          "",
          Validators.required,
          Validators.pattern("^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$"),
        ],
        password: [
          "",
          [
            Validators.required,
            Validators.pattern("(?=.*[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[$!%?_@.#&+-]).\\S{9,15}$")
          ],
        ],
        confirmPassword: ["", Validators.required],
        country: ["", Validators.required],
        checkBox: [false, Validators.requiredTrue],
      },
      {
        validator: MustMatch("password", "confirmPassword"),
      }
    );
  }

  handleKeyUp(e: any) {}

  showModal() {
    // $('#myModal').modal('show');
  }

  onClick() {
    this.EyeFlag = !this.EyeFlag;
  }

  onClickRepeat() {
    this.repeatEyeFlag = !this.repeatEyeFlag;
  }

  // hideModal() {
  //     document.getElementById('close-modal').click();
  // }

  get f() {
    return this.registerForm.controls;
  }

  submitRegister() {
    this.submitted = true;
    if (this.registerForm.invalid) {
      return;
    } else {
      var encrypted = this.set(
        "123456$#@$^@1ERF",
        this.registerForm.value.password.trim()
      );
      let res_obj = {
        language: "en",
        firstName: this.registerForm.value.firstName,
        lastName: this.registerForm.value.lastName,
        email: this.registerForm.value.email.toLowerCase().trim(),
        password: encrypted,
        country: this.registerForm.value.country,
        checkBox: this.registerForm.value.checkBox,
        referalCode: this.registerForm.value.referalCode,
      };

      this.apiService.register(res_obj).subscribe((res: any) => {
        if (res.status === "success") {
          //  this.otpValue = '';
          //  this.showPopup = true;
          if(!res.isUserVerified){
            this.toastrService.success(res.message);
            this.router.navigate(["/login"]);

          }else{
            this.toastrService.success(res.message);
            this.router.navigate(["/login"]);

          }
        } else if (res.status === "fail") {
          this.toastrService.error(res.message);
          this.emailAlreadyTekenError = true;
         // this.toastrService.error("Email Already Taken");
        }
      });
    }
  }

  sendModal() {
    // this.ApiService.
    this.apiService.Confirm(this.f.email.value, this.otpValue).subscribe(
      (data: any) => {
        if (data.status == "success") {
          this.hideModal();
          this.router.navigate(["/login"]);
          this.toastrService.success(
            "Registration Successful. Please Login to continue"
          );
        } else {
          this.showPopup = false;
          this.toastrService.error(data.message.toUpperCase());
        }
      },
      (err) => {
        this.otpError = true;
        this.toastrService.error("Incorrect Code.Pease try again");
      }
    );
  }

  resendOtp() {
    this.otpMessage = false;
    this.apiService.Resendotp(this.f.email.value).subscribe((data: any) => {
      if (data.status === "success") {
        this.otpError = false;
        this.otpMessage = true;
        this.toastrService.success(
          "Code Resent to your Email. Enter Resend code."
        );
      } else {
        this.showPopup = false;
        this.toastrService.error(data.message.toUpperCase());
      }
    });
  }

  hideModal() {
    this.showPopup = !this.showPopup;
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }
}
