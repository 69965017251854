import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { SharedRoutingModule } from "./shared-routing.module";
import { ModalPopupComponent } from "./components/modal-popup/modal-popup.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { VerifyOtpComponent } from "../modules/pages/home/verify-otp/verify-otp.component";
import { WebcamComponent } from "./components/webcam/webcam.component";
import { WebcamModule } from "ngx-webcam";

@NgModule({
  declarations: [ModalPopupComponent, VerifyOtpComponent, WebcamComponent],
  imports: [
    CommonModule,
    SharedRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    WebcamModule,
  ],
  exports: [ModalPopupComponent, VerifyOtpComponent, WebcamComponent],
})
export class SharedModule {}
