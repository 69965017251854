import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { FormGroup, Validators, FormBuilder } from "@angular/forms";
import { ApiService } from "src/app/core/services/api.service";
import * as $ from "jquery";
import { ModalPopupComponent } from "src/app/shared/components/modal-popup/modal-popup.component";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ToastrService } from "ngx-toastr";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { NgxSpinnerService } from "ngx-spinner";
// import { ToastrService } from "ngx-toastr";
import * as CryptoJS from 'crypto-js';
import { ReCaptchaV3Service } from 'ngx-captcha';

@Component({
  selector: "verifyOtpModalContent",
  template: `
    <div class="modal-header">
      <h5 class="modal-title" style="color:#fff">Verify your Account</h5>
      <button
        type="button"
        class="close"
        aria-label="Close"
        (click)="ngbActiveModal.dismiss('Cross click')"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <p class="text-center" style="color:#fff;" *ngIf="otpMessage">
        
        Enter the code we sent to your Registered Email
      </p>
      <div class="row justify-content-center form-group">
        <input
          class="form-control" 
          name="otp"
          [(ngModel)]="otpValue" style="width: 80%;"
          value=""
          placeholder="Enter Code" 
        />
      </div>
      <div class="row h-25 mt-4 pt-2 text-center">
      <div class="col-sm mb-2">
          <button
            type="button"
            class="btn btn-md w-50 btn-primary"
            (click)="verifyOtp()"
          >
            Confirm 
          </button>
        </div>
        <div class="col-sm">
          <button
            type="button"
            class="btn btn-md w-50 btn-primary"
            
            (click)="resendOtp2()"
          >
            Resend Code 
          </button>
        </div>
        
        <!-- background-image: linear-gradient(90deg,#876c1d,#9da063,#7c6526);margin-top: 10px; -->
        <!-- btn btn-lg btn-block bttn gradient-button-red fs-14 -->
        <div class="col-sm text-center">
          <button type="button" style="margin-top: 10px;" class="btn btn-md btn-primary w-50"
            (click)="ngbActiveModal.close('Close click')">Cancel</button>
        </div>
      </div>
    </div>
  `,
  styleUrls: ["./verifyOtpNgbdModalContent.component.css"],
})
export class VerifyOtpNgbdModalContent {
  @Input() email;

  otpValue: any;

  otpMessage = true;
  constructor(
    public ngbActiveModal: NgbActiveModal,
    private apiService: ApiService,
    private router: Router,
    private ngbModalService: NgbModal,
    private toastrService: ToastrService
  ) {
    
   }

  verifyOtp() {
    console.log(`otp value: ${this.otpValue}`);
    var obj = {
      email: this.email,
      otp: this.otpValue,
    };
    this.apiService.verifyOTP(obj).subscribe((res: any) => {
      console.log(`verifyOTP res: ${JSON.stringify(res)} `);
      if (res.status == "success") {
        this.ngbActiveModal.close("Close Click");
        this.apiService.getUserDetails().subscribe(
          (res: any) => {
            console.log(`profile details: ${JSON.stringify(res)}`);
            if (res.status == "success") {
              localStorage.setItem('first_name', res.userInfo['first_name']);
              localStorage.setItem('last_name', res.userInfo['last_name']);
              localStorage.setItem('country_code', ('' + res.userInfo['country']).toLowerCase() || 'us');

            }
            this.toastrService.success('Login Successful', 'Sign-In');
            this.router.navigate(["/pages/dashboard"]);
          },
          (error: HttpErrorResponse) => {
            console.log(`use details error: ${error.error}`);
          }
        );
        // this.toastrService.success('Login Successful', 'Sign-In');
        // this.router.navigate(["/pages/dashboard"]);
      }
      else {
        this.toastrService.error(res.message, 'Sign-In');
      }
    });
  }

  resendOtp2() {
    this.otpMessage = false;
    this.apiService.resendOtp(this.email).subscribe((data: any) => {
      console.log("result", data);
      if (data.status === "success") {
        this.toastrService.success(data.message);
        this.otpMessage = true;
      }
      if (data.status === 'fail') {
        this.ngbModalService.dismissAll();
        // this.ngbActiveModal.close("Close Click");
        this.toastrService.error(data.message);
      }
    });
  }
}

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {
  loginStatus = true;
  loginForm: FormGroup;
  loginErrorMessage = false;
  loginSuccessMessage = false;
  loginOTPSuccessMessage = false;
  loginOTPErrorMessage = false;
  EyeFlag: boolean;
  flang;
  tlang;
  langimg;
  langswitch;
  password;
  showPopup = false;
  siteKey: string;
  theme: string;
  captchaData;
  otp;
  meteDetails;
  resendOtpSuccess = true;
  siteKeyData;
  @ViewChild(ModalPopupComponent) modalPopup;

  loginRes: any;
  loginError = false;
  loginFailMessage: any;
  reCAPTCHA: any;
  encrypted: any;
  decrypted: any;
  ctkn:any;

  // Read template reference
  @ViewChild('captchaElem') captchaElem: ReCaptchaV3Service;
  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private apiService: ApiService,
    private ngbModal: NgbModal,
    private toastrService: ToastrService,
    private httpClient: HttpClient,
    private spinner: NgxSpinnerService,
    private recaptchaV3Service: ReCaptchaV3Service

  ) {
    // this.siteKey = "6Ld8HSYcAAAAAJr2uEUEbD_c01p0LSGVEiwMomx3"
    // (this.siteKey = localStorage.getItem("captchaKey")), (this.theme = "light");
    // this.siteKey = localStorage.getItem("captchaKey");
    // this.theme = "light";
    // console.log(this.apiService.currentUserValue);
    // if (this.apiService.currentUserValue) {
    //   this.router.navigate(["/pages/dashboard"]);
    // }
  }

  ngOnInit(): void {
    // this.apiService.getMeteDetailsLogin().subscribe((data: any) => {
    //     console.log(data);
    //     this.meteDetails = data;
    // });
    this.flang = localStorage.getItem('flang');
    this.tlang = localStorage.getItem('tlang');
    this.langimg = localStorage.getItem('langimg');
    this.langswitch = localStorage.getItem('langswitch');
    this.loginStatus = true;
    this.updateLogin();
    this.captcha();
    // this.fetchIpAddress();
    // this.encrypted = this.set('123456$#@$^@1ERF', 'Shamla@123');
    // console.log(this.encrypted);

    // this.decrypted = this.get('123456$#@$^@1ERF', this.encrypted);
    // console.log(this.decrypted);
  }

  set(keys, value) {
    var key = CryptoJS.enc.Utf8.parse(keys);
    var iv = CryptoJS.enc.Utf8.parse(keys);
    var encrypted = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(value.toString()), key,
      {
        keySize: 128 / 8,
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
      });
    return encrypted.toString();
  }

  //The get method is use for decrypt the value.
  get(keys, value) {
    var key = CryptoJS.enc.Utf8.parse(keys);
    var iv = CryptoJS.enc.Utf8.parse(keys);
    var decrypted = CryptoJS.AES.decrypt(value, key, {
      keySize: 128 / 8,
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
    });

    return decrypted.toString(CryptoJS.enc.Utf8);
  }

  captcha() {
    this.apiService.getCaptcha().subscribe(
      (data) => {
        console.log("dfdf",data);
        this.captchaData = data;
        localStorage.setItem("captchaKey", this.captchaData.siteKey);
        this.siteKey = this.captchaData.siteKey;
        this.theme = "light"
        // console.log(this.siteKey)
      });
  }

  register() {
    // tslint:disable-next-line:no-unused-expression
    this.router.navigate(["/register"]);
  }

  updateLogin() {
    this.loginForm = this.formBuilder.group({
      email: ["", Validators.required],
      password: ["", Validators.required],
      isLoggedIn: true,
      captchaToken: ["", Validators.required],
    });
  }
  handleSuccess(value) {
    console.log(value);
    this.reCAPTCHA = value
  }

  dashboard() {
    this.router.navigate(["/pages/dashboard"]);
  }

  onClick() {
    this.EyeFlag = !this.EyeFlag;
  }

  get f() {
    return this.loginForm.controls;
  }

  handleKeyUp(e: any) {
    // if(e.keyCode == 13){
    //   this.login();
    // }
    console.log(e);
  }

  changed(){
    // console.log('changed');
    this.loginError = false;
  }

  login() {
    this.recaptchaV3Service.execute(this.siteKey, 'login', (token) => {
      this.ctkn = token;
      this.loginForm.get('captchaToken').setValue(token);
      if (!this.loginForm.valid) {
        this.toastrService.error('Invalid Inputs', 'Sign-In');
        return false;
      }
    });
    this.loginErrorMessage = false;
    const isLoggedIn = true;
    this.spinner.show();
    var encrypted = this.set('123456$#@$^@1ERF', this.f.password.value);
    console.log(encrypted);
    this.apiService
      .Login(
        ('' + this.f.email.value).toLowerCase(),
        encrypted,
        isLoggedIn,
        this.f.captchaToken.value,
        // '',
        "",
        this.reCAPTCHA,
        // ''
      )
      .subscribe(
        (data: any) => {
          console.log(data);
          this.spinner.hide();
          if (data.status === "success") {
            if (data.auth2 === true) {
              this.loginErrorMessage = false;
              this.loginRes = data;
              this.openModal();
              this.loginSuccessMessage = true;
              this.loginStatus = !this.loginStatus;
            }
            else if (data.auth2 == false) {
              this.loginErrorMessage = false;
              this.loginSuccessMessage = true;
              this.apiService.getUserDetails().subscribe(
                (res: any) => {
                  if (res.status == "success") {
                    localStorage.setItem('lastLogin', res.userInfo['last_login']);
                    localStorage.setItem('country_code', ('' + res.userInfo['country']).toLowerCase() || 'us');
                    localStorage.setItem('first_name', res.userInfo['first_name']);
                    localStorage.setItem('last_name', res.userInfo['last_name']);
                    localStorage.setItem('country_code', ('' + res.userInfo['country']).toLowerCase() || 'us');
                    localStorage.setItem('claimableBalance', res.userInfo['claimableBalance']);
                    localStorage.setItem('balance', res.userInfo['balance']);
                    localStorage.setItem('kycStatus', res.userInfo['kycStatus']);
                  }
                  this.toastrService.success('Login Successful', 'Sign-In');
                  this.router.navigate(["/pages/dashboard"]);
                },
                (error: HttpErrorResponse) => {
                  console.log(`use details error: ${error.error}`);
                }
              );
            }else{
              this.toastrService.error(data.message)
            }
          }
          if (data.status == 'fail') {
            // this.loginForm.reset();
            // this.captcha();
           // this.captchaElem.resetCaptcha();
            this.loginError = true;
            this.loginFailMessage = data.message;
            // this.toastrService.error(data.message);
          }
        },
        (err) => {
          this.spinner.hide();
          this.loginErrorMessage = true;
        }
      );
  }

  valueChanged(event) {
    // console.log("Value changes..!!");
    this.showPopup = false;
    this.modalPopup.hideModal();
  }

  openModal() {
    console.log("entered into openModal method...");
    const modalRef = this.ngbModal.open(VerifyOtpNgbdModalContent, {
      size: 'sm', backdrop: 'static',
    });
    modalRef.componentInstance.email = ('' + this.loginForm.get("email").value).toLowerCase();
  }

  getUserDetails() {
    this.apiService.getUserDetails().subscribe(
      (res: any) => {
        console.log(`profile details: ${JSON.stringify(res)}`);
        if (res.status == "success") {
          localStorage.setItem('first_name', res.userInfo['first_name']);
          localStorage.setItem('last_name', res.userInfo['last_name']);
        }
      },
      (error: HttpErrorResponse) => {
        console.log(`use details error: ${error.error}`);
      }
    );
  }

  fetchIpAddress() {
    this.httpClient.get('https://jsonip.com/').subscribe(
      (res: any) => {
        // console.log(res);
        localStorage.setItem('ipAddress', res.ip);
        //     let url = `https://api.ipstack.com/134.201.250.155
        // ? access_key = YOUR_ACCESS_KEY`
        // let url = `http://api.ipstack.com/${res.ip}?access_key=844246d670dc5ecbbfcd9d737bd7aa9f`;

        // this.httpClient.get(url).subscribe(
        //   (result: any) => {
        //     console.log(result);
        //     localStorage.setItem('country_code', ('' + result.country_code).toLowerCase());
        //   }
        // );

        let url = `http://www.geoplugin.net/extras/location.gp?ip=${res.ip}&format=json`
        this.httpClient.get(url).subscribe(
          (res: any) => {
            // console.log(res);
            localStorage.setItem('country_code', ('' + res.geoplugin_countryCode).toLowerCase());
          }
        )

      },
      (error) => {
        console.log(error)
      }
    )
  }

  sumbitOTP() {
    this.loginOTPErrorMessage = false;
    const emailId = ('' + this.f.email.value).toLowerCase();
    // console.log(emailId, this.otp);
    const obj = {
      email: emailId,
      otp: this.otp,
    };
    //   console.log(obj);
    this.apiService.verifyOTP(obj).subscribe(
      (data: any) => {
        // console.log(data);
        if (data.status === "success") {
          this.loginOTPErrorMessage = false;
          this.resendOtpSuccess = false;
          this.loginOTPSuccessMessage = true;
          this.router.navigate(["/pages/dashboard"]);
        }
      },
      (err: HttpErrorResponse) => {
        this.loginOTPErrorMessage = true;
      }
    );
  }
  resendOTP() {
    this.resendOtpSuccess = false;
    const emailId = ('' + this.f.email.value).toLowerCase();
    // console.log(emailId);
    this.apiService.resendOtp(emailId).subscribe(
      (data: any) => {
        //  console.log('Otp send Successfull..!!');
        this.resendOtpSuccess = true;
      },
      (err) => {
        this.resendOtpSuccess = false;
      }
    );
  }
  

  
}
