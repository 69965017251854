import { BrowserModule } from "@angular/platform-browser";
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import {
  HttpClientModule,
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
  HTTP_INTERCEPTORS,
} from "@angular/common/http";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { HomeModule } from "./modules/pages/home/home.module";
import { Routes, RouterModule } from "@angular/router";
import { ApiService } from "./core/services/api.service";
import { JwtInterceptor } from "./core/interceptors/jwt.interceptor";
import { ErrorInterceptor } from "./core/interceptors/error.interceptor";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { QRCodeModule } from "angular2-qrcode";
import { UiSwitchModule } from "ngx-ui-switch";
import { MatDialogModule } from "@angular/material/dialog";
import { OutsideDirective } from "./angular.directive";
import { NgxCaptchaModule } from "ngx-captcha";
import { ClipboardModule } from "ngx-clipboard";
import { CountdownModule } from "ngx-countdown";
import { NgSelectModule } from '@ng-select/ng-select';
import { NgOptionHighlightModule } from '@ng-select/ng-option-highlight';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';


@NgModule({
  declarations: [AppComponent, OutsideDirective],
  imports: [
    MatDialogModule,
    RouterModule,
    BrowserModule,
    AppRoutingModule,
    HomeModule,
    ReactiveFormsModule,
    HttpClientModule,
    FormsModule,
    BrowserAnimationsModule,
    QRCodeModule,
    UiSwitchModule,
    ClipboardModule,
    NgxCaptchaModule,
    CountdownModule,
    NgSelectModule,
    NgOptionHighlightModule,
    BsDatepickerModule.forRoot(),
  ],
  providers: [
    ApiService,
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
  bootstrap: [AppComponent],
  exports: [FormsModule, ReactiveFormsModule],
})
export class AppModule {}
