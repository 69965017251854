import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import {
  LoginComponent,
  VerifyOtpNgbdModalContent,
} from "./login/login.component";
import { RegisterComponent, RegErrorMsgModalContent } from "./register/register.component";
import { HomeComponent } from "./home.component";
import { HomeRoutingModule } from "./home-routing.module";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { ResetPasswordComponent } from "./reset-password/reset-password.component";
// import { ModalPopupComponent } from 'src/app/shared/components/modal-popup/modal-popup.component';
import { VerifyOtpComponent } from "./verify-otp/verify-otp.component";
import { SharedModule } from "src/app/shared/shared.module";
import { Ng2TelInputModule } from "ng2-tel-input";
import { CreatepinComponent } from "./createpin/createpin.component";
import { WelcomeComponent } from "./welcome/welcome.component";
import { FaqAndContactGuestComponent } from "./faq-and-contact-guest/faq-and-contact-guest.component";
import { PrivacyPolicyGuestComponent } from "./privacy-policy-guest/privacy-policy-guest.component";
import { TermsConditionGuestComponent } from "./terms-condition-guest/terms-condition-guest.component";
// import { UpdatePinComponent } from "../accounts/update-pin"
import { NgxCaptchaModule } from "ngx-captcha";
import { ToastrModule } from "ngx-toastr"; // msg notification
import { NgxSpinnerModule } from "ngx-spinner";
import { RestrictComponent } from './restrict/restrict.component';
import { GlobalNoticeComponent } from './global-notice/global-notice.component';
import { VerifyComponent } from './verify/verify.component';

@NgModule({
  declarations: [
    HomeComponent,
    LoginComponent,
    RegisterComponent,
    ResetPasswordComponent,
    CreatepinComponent,
    WelcomeComponent,
    FaqAndContactGuestComponent,
    PrivacyPolicyGuestComponent,
    TermsConditionGuestComponent,
    VerifyOtpNgbdModalContent,
    RegErrorMsgModalContent,
    RestrictComponent,
    GlobalNoticeComponent,
    VerifyComponent
    // ModalPopupComponent,
  ],
  imports: [
    CommonModule,
    HomeRoutingModule,
    NgbModule,
    ReactiveFormsModule,
    FormsModule,
    SharedModule,
    Ng2TelInputModule,
    NgxCaptchaModule,
    NgxSpinnerModule,
    ToastrModule.forRoot({
      timeOut: 3000,
      positionClass: "toast-top-right",
      preventDuplicates: true,
    }),
  ],
  exports: [LoginComponent, RegisterComponent],
})
export class HomeModule { }
