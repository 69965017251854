<div class="modal-header">
    <h4 class="modal-title ml-auto">Take Snap</h4>
    <button type="button" class="close" (click)="hideModal()">&times;</button>
</div>

<!-- Modal body -->
<div class="modal-body" style="height: auto;">
    <div class="row justify-content-center form-group">
        <webcam [height]="400" [width]="400" [trigger]="triggerObservable" (imageCapture)="handleImage($event)"
        *ngIf="showWebcam" [allowCameraSwitch]="allowCameraSwitch" [switchCamera]="nextWebcamObservable"
        [videoOptions]="videoOptions" [imageQuality]="1" (cameraSwitched)="cameraWasSwitched($event)"
        (initError)="handleInitError($event)"></webcam>
    </div>
    <div class="row h-25 mt-4 pt-2">
        <div class="col-sm"> <button type="button" style="color: white;"
                class="btn btn-lg btn-block gradient-button fs-14 rounded-pill"
                (click)="triggerSnapshot()">Take Snap</button></div>
        <div class="col-sm"><button type="button" style="color: white;"
                class="btn btn-lg btn-block gradient-button-red fs-14 rounded-pill"
                (click)="hideModal()">Cancel</button>
        </div>
    </div>
    <!-- <p ngIf="otpError==true">**Entered Otp is wrong..!! Try again..!!</p> -->
</div>