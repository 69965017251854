import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { pipe, BehaviorSubject, Observable } from "rxjs";
import { map } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { Router } from "@angular/router";
import { switchMap } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class ApiService {
  ENVIRONMENT_URL = environment.apiUrl;
  headers: HttpHeaders = new HttpHeaders;
  eheaders: HttpHeaders = new HttpHeaders;
  private currentUserSubject: BehaviorSubject<any>;
  public currentUser: Observable<any>;

  constructor(private http: HttpClient, private router: Router) {
    this.currentUserSubject = new BehaviorSubject<any>(
      localStorage.getItem("currentUser")
    );
    this.currentUser = this.currentUserSubject.asObservable();

    // this.currentUserSubject = new BehaviorSubject<any>(
    //   localStorage.getItem("currentUser")
    // );
    // this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue() {
    return this.currentUserSubject.value;
  }

  public get countrycode() {
    let country = "US";
    return false;
  }

 
  Login(email, password, isLoggedIn, captchaToken, ip, reCAPTCHA) {
    const Login = {
      email,
      password,
      captchaToken,
      ip,
      reCAPTCHA
    };
    //  console.log(Login);
    return this.http.post(`${this.ENVIRONMENT_URL}/users/login`, Login).pipe(
      map((user: any) => {
        //  console.log(user);
        if (user.status === "success") {
          if (user.auth2 === false) {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem("token", user.token);
            this.currentUserSubject.next(user);
          }
        }
        return user;
      })
    );
  }

  verifyOTP(data) {
    return this.http.post(`${this.ENVIRONMENT_URL}/users/verify2fa`, data).pipe(
      map((user: any) => {
        //  console.log(user);
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        localStorage.setItem("token", user.token);
        this.currentUserSubject.next(user);
        return user;
      })
    );
  }

  // Added Register service
  Register(
    firstName,
    //  middleName,
    lastName,
    email,
    //  phone,
    //  gender,
    password,
    country,
    language
    // securityQuestion,
    // securityAnswer
  ) {
    const registerForm = {
      firstName,
      //  middleName,
      lastName,
      email,
      // phone,
      //  gender,
      password,
      country,
      language,
      // securityQuestion,
      // securityAnswer,
    };
    // console.log(registerForm);
    return this.http.post(
      `${this.ENVIRONMENT_URL}/users/register`,
      registerForm
    );
  }

  resetPassword(data) {
    return this.http.post(`${this.ENVIRONMENT_URL}/users/forgetPassword`, data);
  }

  registerConfirm(data) {
    return this.http.post(`${this.ENVIRONMENT_URL}/users/confirm`, data);
  }


  // confirm otp
  Confirm(email, otp) {
    const confirmForm = {
      email,
      otp,
    };
    return this.http
      .post(`${this.ENVIRONMENT_URL}/users/confirm`, confirmForm)
      .pipe(
        map((user: any) => {
          //  console.log(user);
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          localStorage.setItem("token", user.token);
          this.currentUserSubject.next(user);
          return user;
        })
      );
  }

  // logged-in
  resendOtp(email) {
    const emailForm = {
      email,
    };
    return this.http.post(
      `${this.ENVIRONMENT_URL}/users/verify2fa/resendOtp`,
      emailForm
    );
  }

  // register
  Resendotp(email) {
    const emailForm = {
      email: email,
    };
    return this.http.post(
      `${this.ENVIRONMENT_URL}/users/register/resendOtp`,
      emailForm
    );
  }

  // Update Profile
  UpdateProfile(
    // meteCallNumber,
    orginalNationality,
    dateOfBirth,
    placeOfBirth,
    hometown,
    motherName,
    fatherName,
    // height,
    // weight,
    //  school,
    gender,
    maritalStatus,
    // kidsNumber,
    mobilePhoneNumber,
    homePhoneNumber,
    fullAddress
  ) {
    const profile = {
      //   meteCallNumber,
      orginalNationality,
      dateOfBirth,
      placeOfBirth,
      hometown,
      motherName,
      fatherName,
      gender,
      //   height,
      //  weight,
      //  school,
      maritalStatus,
      // kidsNumber,
      mobilePhoneNumber,
      homePhoneNumber,
      fullAddress,
    };
    return this.http.post(
      `${this.ENVIRONMENT_URL}/settings/updateProfile`,
      profile
    );
  }

  profilUpdate(data) {
    return this.http.post(
      `${this.ENVIRONMENT_URL}/settings/updateProfile`,
      data
    );
  }

  updatewalletAddress(data) {
    return this.http.post(
      `${this.ENVIRONMENT_URL}/settings/updateExternalWallet`,
      data
    );
  }

  getUserDetails() {
    return this.http.post(`${this.ENVIRONMENT_URL}/detail/user`, {}).pipe(
      map((user: any) => {
        //  console.log(user);
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        localStorage.setItem("currentUser", user.userInfo);
        this.currentUserSubject.next(user.userInfo);
        return user;
      })
    );
  }

  getSecurityQuestion() {
    return this.http.get(
      `${this.ENVIRONMENT_URL}/users/getAvailableSecurityQuestions`
    );
  }

  forgetPassword(data) {
    // const url = this.ENVIRONMENT_URL + '/detail/user';
    return this.http.post(`${this.ENVIRONMENT_URL}/users/forgetPassword`, data);
  }

  forgetPasswordReset(data) {
    return this.http.post(
      `${this.ENVIRONMENT_URL}/users/forgetPassword_reset`,
      data
    );
  }

  logOut() {
    return this.http.post(`${this.ENVIRONMENT_URL}/settings/logout`, {});
  }

  clear() {
    localStorage.removeItem("currentUser");
    localStorage.removeItem("currency value");
    localStorage.clear();
    this.currentUserSubject.next(null);
    this.router.navigate(["/login"]);
  }

  setCurrentUserSubject(data) {
    this.currentUserSubject.next(data);
  }

  changePin(data) {
    return this.http.post(`${this.ENVIRONMENT_URL}/settings/changePin`, data);
  }

  // verified on 16/4/21
  changePassword(data) {
    let headers_ = new HttpHeaders();
    headers_.append("Content-Type", "application/json");
    headers_.append("Authorization", localStorage.getItem("token"));
    return this.http.post(
      `${this.ENVIRONMENT_URL}/settings/changePassword`,
      data,
      { headers: headers_ }
    );
  }

  setSecurityQuestion(data) {
    return this.http.post(
      `${this.ENVIRONMENT_URL}/settings/setSecurityQuestion`,
      data
    );
  }
  updateKYC(data) {
    return this.http.post(`${this.ENVIRONMENT_URL}/settings/updatekyc`, data);
  }

  getMeteDetailsLogin() {
    return this.http.get(`${this.ENVIRONMENT_URL}/detail/MetepriceByIp`);
  }

  getusercountry() {
    return this.http.get(
      `${this.ENVIRONMENT_URL}/users/countryCode`
    );
  }
  getMeteDetailsDashboard() {
    return this.http.get(`${this.ENVIRONMENT_URL}/detail/getLocalPrice`);
  }

  // balance //address
  WalletBalance() {
    return this.http.post(`${this.ENVIRONMENT_URL}/detail/wallet`, {});
  }

  // TransactionHistory
  getTxHistory() {
    return this.http.post(`${this.ENVIRONMENT_URL}/tx/getTxHistory`, {});
  }

  getRefTxHistory() {
    return this.http.post(`${this.ENVIRONMENT_URL}/tx/getRefTxHistory`, {});
  }

  getDisTxHistory() {
    return this.http.post(`${this.ENVIRONMENT_URL}/tx/getDistributionTxHistory`, {});
  }

  getReferalHistory() {
    return this.http.get(`${this.ENVIRONMENT_URL}/detail/getRefDetails`);
  }

  getphases() {
    return this.http.get(`${this.ENVIRONMENT_URL}/settings/getAllPresales`);
  }

  createexchange(data:any) {
    this.checkheader();
    return this.http.post(`${this.ENVIRONMENT_URL}/tx/createexchnage`,data);
  }


  transactionlist() {
    this.checkheader();
    return this.http.get(`${this.ENVIRONMENT_URL}/tx/gettransactions`);

  
  }

  reftransactionlist() {
    this.checkheader();
    return this.http.get(`${this.ENVIRONMENT_URL}/tx/getRefTxHistory`);

  
  }

  distransactionlist() {
    this.checkheader();
    return this.http.get(`${this.ENVIRONMENT_URL}/tx/getDistributionTxHistory`);

  
  }

  filtertransactionlist(data:any) {
    this.checkheader();
    return this.http.post(`${this.ENVIRONMENT_URL}/tx/getfilteredtransactions`,data);
  }

  getexchange(data:any){
    this.checkexchangeheader();
    return this.http.post(`https://api.simpleswap.io/create_exchange?api_key=`+environment.ApiKey,data,{headers: this.eheaders});
  }

  buy(data:any) {
       
    this.checkheader();
    return this.http.post(`${this.ENVIRONMENT_URL}/tx/addbuyTransaction`,data,{headers: this.headers});

  }

  createexchangenw(data:any) {
    this.checkheader();
    return this.http.post(`${this.ENVIRONMENT_URL}/tx/createexchnagenw`,data);
  }

  updatetxhash(data:any) {
    this.checkheader();
    return this.http.post(`${this.ENVIRONMENT_URL}/tx/UpdateTxHash`,data);
  }
  
  getcoinprice(data:any) {
    
    return this.http.post(`${this.ENVIRONMENT_URL}/users/getcoinprice`, data);
  }

  getonecurrencyinfo(data:any){
    
    return this.http.post(`${this.ENVIRONMENT_URL}/users/getonecurrecnydetail`, data);
  }

  buycurrencies() {
    return this.http.get(`${this.ENVIRONMENT_URL}/users/buycurrencylist`);
  }

  getrange(from:any, to:any){
    return this.http.get(`https://api.simpleswap.io/get_ranges?api_key=`+environment.ApiKey+`&fixed=true&currency_from=`+from+`&currency_to=`+to);

  }

  getsettings(){
    https://tradeidouserapi.stsblockchain.xyz/settings/getSettings
    return this.http.get(`${this.ENVIRONMENT_URL}/settings/getSettings`);

  }


  sellcurrencies() {
    return this.http.get(`${this.ENVIRONMENT_URL}/users/sellcurrencylist`);
  }

  DeleteTx(data) {
    return this.http.post(
      `${this.ENVIRONMENT_URL}/tx/deleteOneTransaction`,
      data
    );
  }

  Viewsubref(data) {
    return this.http.post(
      `${this.ENVIRONMENT_URL}/detail/getRefDetailsByEmail`,
      data
    );
  }

  addWhitelist(obj) {
    return this.http.post(`${this.ENVIRONMENT_URL}/settings/addWhitelistEmail`, obj);
  }

  getWhitelist() {
     return this.http.get(`${this.ENVIRONMENT_URL}/settings/whitelistEmails`);
  }

  sendTxMete(data) {
    return this.http.post(`${this.ENVIRONMENT_URL}/tx/send`, data);
  }

  //request private key
  reuestPrivatekey(pin) {
    const verifyPinForm = {
      pin: pin,
    };
    return this.http.post(
      `${this.ENVIRONMENT_URL}/settings/requestPrivateKey`,
      verifyPinForm
    );
  }

  checkheader() {
    const authKey = localStorage.getItem('token');
    if (!!authKey) {
      this.headers = this.headers.set('authorization', `Bearer ${authKey}`);
    }
  }

  checkexchangeheader(){
    this.eheaders = this.eheaders.set('content-type','application/json');
  }

  //PrivateKey
  privateKey(otp) {
    const otpForm = {
      otp: otp,
    };
    return this.http.post(
      `${this.ENVIRONMENT_URL}/settings/checkPrivKeyOTP`,
      otpForm
    );
  }

  //enable 2fa
  enable2fa(status) {
    const enable2fa = {
      status: status,
    };
    return this.http.post(`${this.ENVIRONMENT_URL}/settings/auth2`, enable2fa);
  }

  get2faStatus() {
    return this.http.get(`${this.ENVIRONMENT_URL}/settings/auth2`);
  }

  getContribution() {
    return this.http.get(`${this.ENVIRONMENT_URL}/detail/contributions`);
  }

  getConvertPrice() {
    return this.http.post(`${this.ENVIRONMENT_URL}/tx/coinPriceConvert`, {});
  }

  getConvertPriceNew(data) {
    return this.http.post(`${this.ENVIRONMENT_URL}/tx/coinPriceConvertNew`, data);
  }

  getMinCoinsPayment() {
    return this.http.get(
      `${this.ENVIRONMENT_URL}/tx/getCoinpaymentsMinimum`,
      {}
    );
  }

  exchange(data) {
    return this.http.post(`${this.ENVIRONMENT_URL}/tx/exchange`, data);
  }

  buycheck(data) {
    return this.http.post(`${this.ENVIRONMENT_URL}/tx/buycheck`, data);
  }

 
  buyCrypto(data) {
    return this.http.post(`${this.ENVIRONMENT_URL}/tx/adminAddressQRCode`, data);
   
  }

  sellCrypto(data) {
    return this.http.post(`${this.ENVIRONMENT_URL}/tx/sell`, data);
  }
  sendCrypto(data) {
    return this.http.post(`${this.ENVIRONMENT_URL}/tx/send`, data);
  }
  convertPrice(data) {
    return this.http.post(`${this.ENVIRONMENT_URL}/detail/convertPrice`, data);
  }
  getFiatBalance() {
    return this.http.get(`${this.ENVIRONMENT_URL}/detail/fiatBalance`, {});
  }

  // send kyc docs in base64decode format
  documentVerification_(data) {
    let headers_ = new HttpHeaders();
    headers_.append("Content-Type", "application/json");
    headers_.append("Authorization", localStorage.getItem("token"));
    return this.http.post(
      `${this.ENVIRONMENT_URL}/settings/updatekycnew`,
      data,
      { headers: headers_ }
    );
  }

  // send kyc files directly using formdata
  documentVerification(passport: File, addressProof: File) {
    let headers_ = new HttpHeaders();
    headers_.append("Content-Type", "application/json");
    headers_.append("Authorization", localStorage.getItem("token"));
    const formData: FormData = new FormData();
    // formData.append('passport', passport, passport.name);
    // formData.append('addressProof', addressProof, addressProof.name);

    formData.append("passport", passport);
    formData.append("addressProof", addressProof);
    formData.forEach((value, key) => {
    });
    return this.http.post(
      `${this.ENVIRONMENT_URL}/settings/updatekycnew`,
      formData
    );
  }

  getCaptcha() {
    return this.http.get(`${this.ENVIRONMENT_URL}/users/captcha`, {});
  }

  get_User_Details() {
    let headers_ = new HttpHeaders();
    headers_.append("Content-Type", "application/json");
    headers_.append("Authorization", localStorage.getItem("token"));
    return this.http.post(`${this.ENVIRONMENT_URL}/detail/user`, {
      headers: headers_,
    });
  }

  register(obj) {
    return this.http.post(`${this.ENVIRONMENT_URL}/users/register`, obj);
  }

  getGeoLocation() {
    return this.http.get("https://jsonip.com/").pipe(
      switchMap((value: any) => {
        let url = `http://api.ipstack.com/${value.ip}?accessKey= `;
        return this.http.get(url);
      })
    );
  }

  addTransaction(data) {
    return this.http.post(`${this.ENVIRONMENT_URL}/tx/addTransaction`, data);
  }

  addTransactionAdmin(data) {
    return this.http.post(
      `${this.ENVIRONMENT_URL}/tx/addTransactionadmin`,
      data
    );
  }
}
