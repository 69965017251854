import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-faq-and-contact-guest',
  templateUrl: './faq-and-contact-guest.component.html',
  styleUrls: ['./faq-and-contact-guest.component.scss']
})
export class FaqAndContactGuestComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
