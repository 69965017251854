import { Component, OnInit  } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { catchError, tap} from "rxjs/operators";
import { Router } from '@angular/router';
import { ApiService } from './core/services/api.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent {
    title = 'finsoftlab';

    constructor(
        private http: HttpClient,
        private router: Router,
        private apiService: ApiService
    ) { 
    
    }

    
}
