import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { VerifyOtpComponent } from './verify-otp/verify-otp.component';
import { CreatepinComponent } from './createpin/createpin.component';
import { WelcomeComponent } from './welcome/welcome.component';
import { FaqAndContactGuestComponent } from './faq-and-contact-guest/faq-and-contact-guest.component';
import { PrivacyPolicyGuestComponent } from './privacy-policy-guest/privacy-policy-guest.component';
import { TermsConditionGuestComponent } from './terms-condition-guest/terms-condition-guest.component';
import { GlobalNoticeComponent } from './global-notice/global-notice.component';
// import { UpdatePinComponent } from "../../pages/accounts"
import { VerifyComponent } from './verify/verify.component';
import { RestrictComponent } from './restrict/restrict.component';

const routes: Routes = [
    {
        path: '',
        component: LoginComponent,
    },
    {
        path: 'login',
        component: LoginComponent,
    },
    {
        path: 'register',
        component: RegisterComponent,
    },
    {
        path: 'reset-password',
        component: ResetPasswordComponent,
    },
    {
        path: 'verify-otp',
        component: VerifyOtpComponent,
    },
    {
        path: 'verify',
        component: VerifyComponent,
    },
    {
        path: 'createpin',
        component: CreatepinComponent
    },
    {
        path: 'welcome',
        component: WelcomeComponent
    },
    // {
    //     path: 'update-pin',
    //     component: UpdatePinComponent,
    // },
    {
        path: 'faq-contacts',
        component: FaqAndContactGuestComponent,
    },
    {
        path: 'privacy-policy',
        component: PrivacyPolicyGuestComponent,
    },
    {
        path: 'terms-conditions',
        component: TermsConditionGuestComponent,
    },
    {
        path: 'global-notice',
        component:  GlobalNoticeComponent,
    },

    {
        path: 'invite',
        component: RegisterComponent,
    },
    {
        path: 'restrict',
        component: RestrictComponent,
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class HomeRoutingModule {}
