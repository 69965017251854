  <!-- Apply the background styles to the body element -->
  <body style="background-color: #2c2c2e; color: white; margin: 0; padding: 0;">

   

    <!-- Your page content here -->

    <ng-container *ngIf="!verfystatus">
        <!-- Fixed-position overlay for verification message -->
        <div style="position: fixed; top: 0; left: 0; width: 100%; height: 100%; background-color: rgba(0, 0, 0, 0.8); display: flex; justify-content: center; align-items: center; z-index: 999;">

         

            <div style="background-color: black; padding: 20px; text-align: center; color: white;">

                <img class="mb-3" src="assets/images/logo.png" alt="" style="width: 250px;margin-top: 11px;margin-left: -10px"/> 

                <br/><br/>


                <h1>Verifying...</h1>
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="verfystatus && bckendstatus=='Token expired'">
        <!-- Fixed-position overlay for verification message -->
        <div style="position: fixed; top: 0; left: 0; width: 100%; height: 100%; background-color: rgba(0, 0, 0, 0.8); display: flex; justify-content: center; align-items: center; z-index: 999;">

         

            <div style="background-color: black; padding: 20px; text-align: center; color: white;">

                <img class="mb-3" src="assets/images/logo.png" alt="" style="width: 250px;margin-top: 11px;margin-left: -10px"/> 

                <br/>    <br/> 


                
                <h1>Token Expired</h1>

                <br/>  
                <p>It looks like you may have clicked on an invalid email verification link. <br> Please try <a [routerLink]="['/login']" style="color: #f23269 !important;">login</a> with the registered details to get new verification link </p>
        <br/>  



        <br/>  

            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="verfystatus && bckendstatus=='Success'">
        <!-- Fixed-position overlay for verification message -->
        <div style="position: fixed; top: 0; left: 0; width: 100%; height: 100%; background-color: rgba(0, 0, 0, 0.8); display: flex; justify-content: center; align-items: center; z-index: 999;">

         

            <div style="background-color: black; padding: 20px; text-align: center; color: white;">

                <img class="mb-3" src="assets/images/logo.png" alt="" style="width: 250px;margin-top: 11px;margin-left: -10px"/> 

                <br/>    <br/> 


                
                <h1>Your email verified sucessfully.</h1>

                <br/>  
                <p>Please try <a [routerLink]="['/login']" style="color: #f23269 !important;">login</a> with the registered details </p>
        <br/>  



        <br/>  

            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="verfystatus && bckendstatus=='Already Registered'">
        <!-- Fixed-position overlay for verification message -->
        <div style="position: fixed; top: 0; left: 0; width: 100%; height: 100%; background-color: rgba(0, 0, 0, 0.8); display: flex; justify-content: center; align-items: center; z-index: 999;">

         

            <div style="background-color: black; padding: 20px; text-align: center; color: white;">

                <img class="mb-3" src="assets/images/logo.png" alt="" style="width: 250px;margin-top: 11px;margin-left: -10px"/> 

                <br/>    <br/> 


                
                <h1>Already Registered</h1>

                <br/>  
                <p>It looks like you may have already verified your email address. Please try <a [routerLink]="['/login']" style="color: #f23269 !important;">login</a> with the registered details </p>
        <br/>  



        <br/>  

            </div>
        </div>
    </ng-container>

</body>



    



