import { Component, OnInit, ViewChild } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import Stepper from "bs-stepper";
import { ApiService } from "src/app/core/services/api.service";
import { Router } from "@angular/router";
import { MustMatch } from "../register/mustMatch";
import { ToastrService } from "ngx-toastr";
import * as CryptoJS from 'crypto-js';
import { ReCaptchaV3Service } from 'ngx-captcha';

@Component({
  selector: "app-reset-password",
  templateUrl: "./reset-password.component.html",
  styleUrls: ["./reset-password.component.scss"],
})
export class ResetPasswordComponent implements OnInit {
  resetPasswordForm: FormGroup;
  showStep1: boolean = true;
  showStep2: boolean = false;
  showStep3: boolean = false;

  showStep1Err: boolean = false;
  showStep2Err: boolean = false;

  Questions;
  questionArray = [];
  questionGroupError = false;
  submittedQuestionGroup = false;
  submittedResetPassword = false;
  resetPasswordError = false;
  EyeFlag: boolean;
  repeatEyeFlag: boolean;

  // thiru
  submitted = false;
  siteKey: string;
  theme: string;
  captchaData;
  errorMsg: any;
  reCAPTCHA: any;
  ctkn: any;

  // Read template reference
  @ViewChild('captchaElem') captchaElem: ReCaptchaV3Service;

  constructor(
    private formBuilder: FormBuilder,
    private apiService: ApiService,
    private router: Router,
    private toastrService: ToastrService,
    private recaptchaV3Service: ReCaptchaV3Service

  ) { 
  
  }

  handleKeyUp(e: any) {
    console.log(e);
  }

  ngOnInit(): void {
    //   console.log(this.resetPasswordForm);
    // this.showStep1 = true;
    this.updatepasswordForm();
    this.captcha();
    // this.getSecurityQuestion();
  }


  captcha() {
    this.apiService.getCaptcha().subscribe((data) => {
      console.log(data);
      this.captchaData = data;
      localStorage.setItem("captchaKey", this.captchaData.siteKey);
      this.siteKey = this.captchaData.siteKey;
      this.theme = "light"
      this.recaptchaV3Service.execute(this.siteKey, 'login', (token) => {
        this.reCAPTCHA = token;
       
      });
      // console.log(this.siteKey)
    });
  }

  handleSuccess(value) {
    console.log(value);
    this.reCAPTCHA = value;
    this.showStep1Err = false;
    this.submittedResetPassword = true;
  }

  get f() {
    return (this.resetPasswordForm.get("questionGroup") as FormGroup).controls;
  }
  get f1() {
    return (this.resetPasswordForm.get("submitFormGroup") as FormGroup)
      .controls;
  }

  onClick() {
    this.EyeFlag = !this.EyeFlag;
  }

  onClickRepeat() {
    this.repeatEyeFlag = !this.repeatEyeFlag;
  }

  // "(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-zd$@$!%*?&].{8,}"
  updatepasswordForm() {
    this.resetPasswordForm = this.formBuilder.group({
      questionGroup: this.formBuilder.group({
        email: [
          "",
          Validators.compose([
            Validators.required,
            Validators.email,
            // Validators.pattern(
            //   "^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$"
            // ),
          ]),
        ],
        captchaToken: [null, Validators.required]
        // securityQuestion: ["", Validators.required],
        // securityAnswer: ["", Validators.required],
      }),
      submitFormGroup: this.formBuilder.group(
        {
          otp: ["", Validators.required],
          newPassword: [
            "",
            [
              Validators.required,
              Validators.pattern("(?=.*[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[$!%?_@.#&+-]).\\S{9,15}$")
            ]
          ],
          confirmPassword: ["", Validators.required]
        },
        {
          validator: MustMatch("newPassword", "confirmPassword"),
        }
      ),
    });
  }

  changed(){
    console.log('reset changed');
    this.showStep1Err = false;
  }

  step2Func() {
    this.recaptchaV3Service.execute(this.siteKey, 'login', (token) => {
      this.reCAPTCHA = token;
     
    });
    console.log(this.reCAPTCHA);
    if (this.f.invalid) {
      return;
    }
    // console.log(this.f);
    const obj = {
      email: this.f.email.value,
      reCAPTCHA: this.reCAPTCHA
      // securityQuestion: this.f.securityQuestion.value,
      // securityAnswer: this.f.securityAnswer.value,
    };
    console.log(obj);
    this.apiService.forgetPassword(obj).subscribe(
      (data: any) => {
        console.log(data);
        if (data.status === "success") {
          this.showStep1 = false;
          this.showStep2 = true;
          // this.captcha();
        }
        else if (data.status == 'fail') {
          // this.showStep3 = true;
          this.showStep1Err = true;
          this.errorMsg = data.message;
         // this.captchaElem.resetCaptcha();
          // console.log(this.showStep3);
        }
      },
      (err) => {
        this.questionGroupError = true;
      }
    );
  }

  back() {
    this.showStep1 = true;
    this.showStep2 = false;
  }

  // searchData(value) {
  //     console.log(value);
  //     this.getSecurityQuestion();
  // }

  getSecurityQuestion() {
    // console.log(this.f.questionGroup);
    // const obj = {
    //     email: this.f.email.value,
    // };
    this.apiService.getSecurityQuestion().subscribe((data: any) => {
      //console.log(data);
      this.questionArray = data.questions;
    });
  }

  set(keys, value) {
    var key = CryptoJS.enc.Utf8.parse(keys);
    var iv = CryptoJS.enc.Utf8.parse(keys);
    var encrypted = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(value.toString()), key,
      {
        keySize: 128 / 8,
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
      });
    return encrypted.toString();
  }

  onSubmit() {
    this.resetPasswordError = false;
    this.submittedResetPassword = true;
    if (this.f1.invalid) {
      return;
    }
    // console.log(this.f1);

    var encrypted = this.set('123456$#@$^@1ERF', this.f1.newPassword.value);
    const obj = {
      otp: this.f1.otp.value,
      newPassword: encrypted,
      email: this.f.email.value
    };
    // console.log(obj)
    this.apiService.forgetPasswordReset(obj).subscribe(
      (data: any) => {
        // console.log(data);
        if (data.status === "success") {
          this.router.navigate(["/login"]);
          this.toastrService.success(data.message, "Password Reset Request");
        } else if (data.status === "fail") {
          this.router.navigate(["/reset-password"]);
          this.toastrService.error(data.message, "Password Reset Request");
        }
      },
      (err) => {
        this.resetPasswordError = true;
      }
    );
  }


}
