<!DOCTYPE html>
<html lang="en">

<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Defi wallet | Privacy Policy</title>

    <!-- BOOTSTRAP CSS -->
    <!-- <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.5.2/css/bootstrap.min.css" integrity="sha384-JcKb8q3iqJ61gNV9KGb8thSsNjpSL0n8PARn9HuZOnIxN0hoP+VmmDGMN5t9UJ0Z" crossorigin="anonymous"> -->

    <!-- CUSTOM CSS -->
    <style>
        p:last-child {
            margin-bottom: 0;
        }
    </style>
</head>

<body>
    <div class="container">
        <div class="flex-container">
            
            <a [routerLink]="['/pages/dashboard']" routerLinkActive="router-link-active" 
            class="btn btn-sm btn-outline btn-light">Back
            </a>

            <br>

            <div class="clearfix mb-5"></div>

            <h2 class="text-secondary">DeFi Global Notice & Copyright Guidelines</h2>

            

        </div>
        <!-- pt-5 -->
        <div class="container  pb-5" style="text-align: justify;">
            <!-- <h2 class="mb-4 text-secondary">Privacy Policy</h2> -->
            <p><strong>Notice:</strong></p>
            <p>The information contained in the referring website is intended for the DeFi 
                Global Solutions Private Member Association (DeFiPMA). You are hereby provided 
                notice that The DeFiPMA policies outlined in this DeFi Legal Section are 
                consistent with international treaties that are applicable in most countries. 
                If you are not a DeFiPMA Member you are welcome to 
                visit and observe but not violate the following Copyright Guidelines. </p>

                <p><strong>Copyright Guidelines:</strong></p>


                <p>The laws governing creative works and their permissible use vary from one country 
                    to another. The DEFiPMA policies outlined in this section are consistent with international 
                    treaties that are applicable in most countries. For simplicity, this section refers to a creator’s 
                    rights as “copyright.” 
                    However, certain of these rights may be known by different names in some countries.</p>


                   <p>Copyright is a protection given by law to the creators of original works of authorship 
                    that are expressed in a tangible form, including:</p> 


                    <p>Mobile application or operating systems.Computer programs or games.Internet and other 
                        databases.Literary, musical, dramatic, and choreographic works.Works of art, photography, 
                        and sculpture.Audio and audiovisual works (such as movies and videos, CDs, and DVDs).</p>


                        <p>Website vistors should strictly observe all copyright laws. Generally, only copyright 
                            owners may authorize duplication (copying), distribution, public performance, public display, 
                            or derivatives of their work. Using a work in any of these ways without authorization from 
                            the copyright owner is contrary to the DeFiPMA  
                            policy and may also subject the visitor to legal liability.</p>




        </div>
    </div>

    <!-- BOOTSTRAP SCRIPTS -->
    <script src="https://code.jquery.com/jquery-3.5.1.slim.min.js"
        integrity="sha384-DfXdz2htPH0lsSSs5nCTpuj/zy4C+OGpamoFVy38MVBnE+IbbVYUew+OrCXaRkfj"
        crossorigin="anonymous"></script>
    <script src="https://cdn.jsdelivr.net/npm/popper.js@1.16.1/dist/umd/popper.min.js"
        integrity="sha384-9/reFTGAW83EW2RDu2S0VKaIzap3H66lZH81PoYlFhbGU+6BZp6G7niu735Sk7lN"
        crossorigin="anonymous"></script>
    <script src="https://stackpath.bootstrapcdn.com/bootstrap/4.5.2/js/bootstrap.min.js"
        integrity="sha384-B4gt1jrGC7Jh4AgTPSdUtOBvfO8shuf57BaghqFfPlYxofvL8/KUEfYiJOMMV+rV"
        crossorigin="anonymous"></script>
</body>

</html>