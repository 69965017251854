import { Directive,ElementRef } from '@angular/core';

@Directive({
  selector: '[appOutside]',
   host: {
   '(document:click)': 'onClick($event)',
  }
})
export class OutsideDirective {

  constructor(private elementRef: ElementRef) { }

  

}