import { NgModule } from "@angular/core";
import { Routes, RouterModule, PreloadAllModules } from "@angular/router";
import { AuthGuard } from "./core/guards/auth-guard.guard";
import { IpGuard } from "./core/guards/ip.guard";

const routes: Routes = [
  {
    path: "",
    loadChildren: () =>
      import("./modules/pages/home/home.module").then((m) => m.HomeModule),
     //canActivate: [IpGuard],
  },
  {
    path: "pages",
    loadChildren: () =>
      import("./modules/pages/pages.module").then((n) => n.PagesModule),
    canActivate: [AuthGuard],
  },
];
//AuthGuard
@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: true,
      preloadingStrategy: PreloadAllModules,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
