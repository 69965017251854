import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
// import { ToastrService } from "ngx-toastr";

@Component({
  selector: "app-welcome",
  templateUrl: "./welcome.component.html",
  styleUrls: ["./welcome.component.scss"],
})
export class WelcomeComponent implements OnInit {
  constructor(private router: Router,
    // private toastr: ToastrService,
    ) {}

  ngOnInit(): void {}

  click() {
    console.log("hiiiiiiiiiiiiiiiii");
    
    this.router.navigate(["/pages/dashboard"]);
    // this.toastr.success("Logged InSuccessfully")
  }
}
