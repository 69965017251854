<div class="page-content">
    <div class="change-pin-page">
        <div class="w-center-medium">
            <div class="bg-white-smoke-2 rounded-lg p-5">

                <p class="fs-16 font-weight-bold text-primary-dark text-center mb-5">
                    Change Pin
                </p>
                <form [formGroup]="changePinForm">
                    <!-- <div class="form-group">
                        <div class="row justify-content-center">
                            <input class="form-control" [ngClass]="{ 'not-valid': submitted && f.oldPin.errors }"
                                name="old pin" type="password" placeholder="Enter Old Pin" formControlName="oldPin" />
                        </div>
                       
                    </div> -->
                    <div class="form-group form">
                        <div class="row justify-content-center position-relative">
                            <input class="form-control" [type]="EyeFlag ? 'text' : 'password'"
                                [ngClass]="{ 'not-valid': submitted && f.newPin.errors }" placeholder="Enter New Pin"
                                formControlName="newPin" name="new pin" />
                                <i  
                                class="fa" [ngClass]="{
                                    'fa-eye-slash': !EyeFlag,
                                    'fa-eye': EyeFlag
                                  }"
                                  (click)="onClick()"
                                ></i>  
                        </div>
                        <div class="row justify-content-center mt-2">
                            <div *ngIf="submitted && f.newPin.errors " class="alert-box w-50">

                                <div *ngIf="f.newPin.errors.required" class="ml-2 fs-14">
                                    New pin is required.
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group forms">
                        <div class="row justify-content-center position-relative">
                            <input class="form-control" [type]="repeatEyeFlag ? 'text' : 'password'"
                                [ngClass]="{ 'not-valid': submitted && f.confirmNewPin.errors }"
                                placeholder="Confirm New Pin" formControlName="confirmNewPin" name="confirm new pin" />
                                <i  
                                class="fa" [ngClass]="{
                                    'fa-eye-slash': !repeatEyeFlag,
                                    'fa-eye': repeatEyeFlag
                                  }"
                                  (click)="onClickRepeat()"
                                ></i>
                        </div>
                        <div class="row justify-content-center mt-2">
                            <div *ngIf="submitted && f.confirmNewPin.errors && !f.newPin.errors" class="alert-box w-50">

                                <div *ngIf="f.confirmNewPin.errors.required" class="ml-2 fs-14">
                                    Confirm new pin required.
                                </div>
                                <div *ngIf="f.confirmNewPin.errors.mustMatch" class="ml-2 fs-14">
                                    Confirm new pin must be match.
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row justify-content-center mt-2">
                        <div *ngIf="submitted && changePinError" class="alert-box w-50">
                            <p class="ml-2">Incorrect old pin..!! Try again..!!</p>

                        </div>
                    </div>

                    <div class="form-group mt-4">
                        <div class="text-center">
                            <button class="btn btn btn-primary btn-block text-white" type="button" style=" background:#7c6526; color: white;border-color: #7c6526;"
                                (click)="onSubmit()">
                                Save
                            </button>
                        </div>
                    </div>

                </form>
            </div>
        </div>
    </div>
</div>