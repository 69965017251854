<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Viblos | FAQ's</title>

    <!-- BOOTSTRAP CSS -->
    <!-- <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.5.2/css/bootstrap.min.css" integrity="sha384-JcKb8q3iqJ61gNV9KGb8thSsNjpSL0n8PARn9HuZOnIxN0hoP+VmmDGMN5t9UJ0Z" crossorigin="anonymous"> -->

    <!-- CUSTOM CSS -->
    <style>
        .card {
            border-bottom: 1px solid #dee2e6 !important;
            border-radius: 0;
            margin-bottom: .5rem;
        }

        .card-header {
            color: #fff;
            background: #6c757d;
            cursor: pointer;
        }
    </style>
</head>
<body>

    <div class="container pt-5 pb-5">
        <h4 class="mb-4 text-secondary">Frequently Asked Questions</h4>
    
        <div class="accordion" id="faqs">
            <div class="card">
                <div class="card-header" id="headingOne">
                    <h6 class="mb-0" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                        What is the work of Viblos?
                    </h6>
                </div>
            
                <div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-parent="#faqs">
                    <div class="card-body">
                        Viblos's intends to deliver a truly decentralized blockchain based network for cross-border payment settlements. It is a real-time gross settlement platform backed by PAZ as a native cryptocurrency.
                    </div>
                </div>
            </div>

            <div class="card">
                <div class="card-header" id="headingTwo">
                    <h6 class="mb-0" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                        How Viblos will change finance, government, public and private sector?
                    </h6>
                </div>
            
                <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#faqs">
                    <div class="card-body">
                        Viblos focuses on focusing on low-cost cross-border payments, faster processing, transaparency, high security and advanced data analytics for easier reporting that will become the trend setter.
                    </div>
                </div>
            </div>

            <div class="card">
                <div class="card-header" id="headingThree">
                    <h6 class="mb-0" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                        What are the services Viblos offers and how it will help to develop business?
                    </h6>
                </div>
            
                <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#faqs">
                    <div class="card-body">
                        Viblo provides various services like escrow and cross border payment at minimal charges to help your gain maximum profits.
                    </div>
                </div>
            </div>

            <div class="card">
                <div class="card-header" id="headingFour">
                    <h6 class="mb-0" data-toggle="collapse" data-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                        Can we use blockchain of Viblos for multipurpose?
                    </h6>
                </div>
            
                <div id="collapseFour" class="collapse" aria-labelledby="headingFour" data-parent="#faqs">
                    <div class="card-body">
                        A Blockchain is a decentralized database containing time-stamped records customizable for any sector. Here Viblos uses Blockchain-based solution for real-time gross settlement to facilitate secured cross-border payments.
                    </div>
                </div>
            </div>

            <div class="card">
                <div class="card-header" id="headingFive">
                    <h6 class="mb-0" data-toggle="collapse" data-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                        It is private or public blockchain?
                    </h6>
                </div>
            
                <div id="collapseFive" class="collapse" aria-labelledby="headingFive" data-parent="#faqs">
                    <div class="card-body">
                        We offer public blockchain services.
                    </div>
                </div>
            </div>

            <div class="card">
                <div class="card-header" id="headingSix">
                    <h6 class="mb-0" data-toggle="collapse" data-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                        What are the advantages of Viblos?
                    </h6>
                </div>
            
                <div id="collapseSix" class="collapse" aria-labelledby="headingSix" data-parent="#faqs">
                    <div class="card-body">
                        Viblos promises enhance performance, complete transparency and enhanced security with the documentation the robust blockchain technology provides. We also adhere to regulatory frameworks ensuring utmost credibility and safety.
                    </div>
                </div>
            </div>

            <div class="card">
                <div class="card-header" id="headingSeven">
                    <h6 class="mb-0" data-toggle="collapse" data-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
                        How fast blockchain of Viblos can work?
                    </h6>
                </div>
            
                <div id="collapseSeven" class="collapse" aria-labelledby="headingSeven" data-parent="#faqs">
                    <div class="card-body">
                        Our focus is to provide the fastest ever platform. We are targeting a fast and scalable platform that allows upto 60,000 transactions per second (tps).
                    </div>
                </div>
            </div>

            <div class="card">
                <div class="card-header" id="headingEight">
                    <h6 class="mb-0" data-toggle="collapse" data-target="#collapseEight" aria-expanded="false" aria-controls="collapseEight">
                        Is it scalable for industries, financial firm and any other business?
                    </h6>
                </div>
            
                <div id="collapseEight" class="collapse" aria-labelledby="headingEight" data-parent="#faqs">
                    <div class="card-body">
                        We assure high scalability and advancements to be the most suitable for any firm or industry. Viblos will leverage a large network of micro-payment channels to address the scalability challenge.
                    </div>
                </div>
            </div>

            <div class="card">
                <div class="card-header" id="headingNine">
                    <h6 class="mb-0" data-toggle="collapse" data-target="#collapseNine" aria-expanded="false" aria-controls="collapseNine">
                        How to connect with team and support team of Viblos?
                    </h6>
                </div>
            
                <div id="collapseNine" class="collapse" aria-labelledby="headingNine" data-parent="#faqs">
                    <div class="card-body">
                        We provide round the clock support to help you in each and every query. Please call us at (number) or mail to (mail).
                    </div>
                </div>
            </div>

            <div class="card">
                <div class="card-header" id="headingTen">
                    <h6 class="mb-0" data-toggle="collapse" data-target="#collapseTen" aria-expanded="false" aria-controls="collapseTen">
                        When Mainnet will be available for public use?  
                    </h6>
                </div>
            
                <div id="collapseTen" class="collapse" aria-labelledby="headingTen" data-parent="#faqs">
                    <div class="card-body">
                        The mainnet will be available from 1st of August, 2021.
                    </div>
                </div>
            </div>

        </div>
    </div>


    <!-- BOOTSTRAP SCRIPTS -->
    <script src="https://code.jquery.com/jquery-3.5.1.slim.min.js" integrity="sha384-DfXdz2htPH0lsSSs5nCTpuj/zy4C+OGpamoFVy38MVBnE+IbbVYUew+OrCXaRkfj" crossorigin="anonymous"></script>
    <script src="https://cdn.jsdelivr.net/npm/popper.js@1.16.1/dist/umd/popper.min.js" integrity="sha384-9/reFTGAW83EW2RDu2S0VKaIzap3H66lZH81PoYlFhbGU+6BZp6G7niu735Sk7lN" crossorigin="anonymous"></script>
    <script src="https://stackpath.bootstrapcdn.com/bootstrap/4.5.2/js/bootstrap.min.js" integrity="sha384-B4gt1jrGC7Jh4AgTPSdUtOBvfO8shuf57BaghqFfPlYxofvL8/KUEfYiJOMMV+rV" crossorigin="anonymous"></script>
</body>
</html>