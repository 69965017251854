import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { Router } from "@angular/router";
import { ApiService } from "src/app/core/services/api.service";

declare var $: any;
@Component({
  selector: "app-verify-otp",
  templateUrl: "./verify-otp.component.html",
  styleUrls: ["./verify-otp.component.scss"],
})
export class VerifyOtpComponent implements OnInit {
  otpValue;
  otpError;
  otpMessage = true;
  // tslint:disable-next-line:no-input-rename
  @Input("email") email;
  @Output() eventChanged = new EventEmitter();
  constructor(private router: Router, private apiService: ApiService) {}

  ngOnInit(): void {
    console.log(this.email);
    $("#myModal").modal("show");
  }

  handleKeyUp(e: any) {
    console.log(e);
  }

  sendModal() {
    // this.ApiService.
    console.log(this.otpValue);
    this.apiService.Confirm(this.email, this.otpValue).subscribe(
      (data: any) => {
        console.log(data);
        this.hideModal();
        this.router.navigate(["/pages/update-pin"]);
      },
      (err) => {
        this.otpError = true;
      }
    );
  }

  resendOtp() {
    console.log(this.email);
    this.otpMessage = false;
    this.apiService.Resendotp(this.email).subscribe((data: any) => {
      console.log("result", data);
      if (data.status === "success") {
        this.otpError = false;
        this.otpMessage = true;
      }
    });
  }

  hideModal() {
    console.log("change working");
    this.eventChanged.emit("value");
  }
}
