<!-- <div (keydown.enter)="handleKeyUp($event)">
  <div class="page-ath-wrap">
    <div class="page-ath-content">
      <div class="page-ath-header">
        <a href="./" class="page-ath-logo logInlogo"><img src="assets/images/app-logo.png" alt="logo" /></a>
      </div>
      <div class="page-ath-form">
        <h2 class="page-ath-heading">
          Sign in <small>with your Nugen Account</small>
        </h2>
        <form [formGroup]="loginForm" (submit)="login()">
          <div class="form-group">
            <input class="input-bordered" placeholder="Your Email Address" type="text" formControlName="email" />
          </div>
          <div class="form-group">
            <input class="input-bordered" [type]="EyeFlag ? 'text' : 'password'" type="text" placeholder="Your Password"
              formControlName="password" (keydown.enter)="handleKeyUp($event)" />
            <i class="fa pass" [ngClass]="{
                'fa-eye-slash': !EyeFlag,
                'fa-eye': EyeFlag
              }" (click)="onClick()"></i>
            <p *ngIf="loginErrorMessage" style="color: white; background-color: #f45662;" class="alert-box fs-16">
              **Incorrect credentials..!! Please try again..!!
            </p>
            <div class="d-flex justify-content-between align-items-center mt-4">
              <div style="margin-top: -20px; font-size: 13px;">
                <a routerLink="/reset-password">Forgot password?</a>
                <div class="gaps-2x"></div>
              </div>
            </div>
          </div>
          <div style="margin-top: -10px; margin-bottom: 10px;">
            <ngx-recaptcha2 #captchaElem [theme]="this.theme" [siteKey]="this.siteKey" formControlName="captchaToken"
              (success)="handleSuccess($event)">
            </ngx-recaptcha2>
          </div>
          <button class="btn btn-primary btn-block">Sign In</button>
          <div class="text-center mt-3 form-group">
            <p class="text-white">
              Don’t have an account?
              <a routerLink="/register" class="text-white">Sign up here</a>
            </p>
          </div>
        </form>
        <div class="form-note">
          Don’t have an account?
          <a routerLink="/register"> <strong>Sign up here</strong></a>
        </div>
      </div>
      <div class="page-ath-footer">
        <ul class="footer-links">
          <li><a routerLink="/privacy-policy">Privacy Policy</a></li>
          <li><a routerLink="/terms-conditions">Terms</a></li>
          <li>&copy; 2022 Nugencoin.</li>
        </ul>
      </div>
    </div>
    <div class="page-ath-gfx">
      <div class="w-100 d-flex justify-content-center">
        <div class="col-md-12 col-xl-8">
          <img src="../../../../../assets/images/signin-img.svg" alt="image" />
        </div>
      </div>
    </div>
  </div>
</div> -->

<ngx-spinner>
</ngx-spinner>

<body class="page-ath">
  <div class="page-ath-wrap lr-main">
    <div class="page-ath-content">
      <div class="page-ath-form">
        
        <div class="au_bg">
          <!-- <small>with your Nugen Account</small> autocomplete="nope"-->
          <h2 class="page-ath-heading loginTitle">Sign in</h2>
          <form [formGroup]="loginForm" (submit)="login()">
            <div class="form-group">
              <input class="input-bordered" autocomplete="new-password" placeholder="Your Email Address" type="text"
                formControlName="email" (change)="changed()" />
            </div>
            <div class="form-group">
              <input class="input-bordered" autocomplete="new-password" [type]="EyeFlag ? 'text' : 'password'"
                type="text" placeholder="Your Password" formControlName="password" (keydown.enter)="handleKeyUp($event)"
                (change)="changed()" />
              <i class="fa pass" [ngClass]="{
                'fa-eye-slash': !EyeFlag,
                'fa-eye': EyeFlag
              }" (click)="onClick()"></i>
              <p *ngIf="loginErrorMessage" style="color: white; background-color: #f45662;" class="alert-box fs-16">
                **Incorrect credentials..!! Please try again..!!
              </p>
              <p *ngIf="loginError" style="color: white; background-color: #f45662;" class="alert-box fs-16">
                {{loginFailMessage}}
              </p>
              <div class="d-flex justify-content-between align-items-center mt-4">
                <div style="margin-top: -20px; font-size: 13px;">
                  <a routerLink="/reset-password">Forgot password?</a>
                  <div class="gaps-2x"></div>
                </div>
              </div>
            </div>
            <div style="margin-top: -10px; margin-bottom: 10px;" *ngIf="siteKey">

              <ngx-invisible-recaptcha #captchaElem [siteKey]="this.siteKey" formControlName="captchaToken"
                (success)="handleSuccess($event)">
              </ngx-invisible-recaptcha>


            </div>
            <button class="btn btn-primary btn-block">Sign In</button>
            <!-- <button class="btn btn-primary btn-block" [disabled]='!loginForm.valid'>Sign In</button> -->

            <div class="text-center mt-3 form-group">
              <p class="text-white">
                Don’t have an account?
                <a routerLink="/register" class="text-white">Sign up here</a>
              </p>
            </div>
          </form>
          <!-- <div class="sap-text mt-2"><span>Or Sign In With</span></div> -->

          <!-- <ul class="row guttar-20px guttar-vr-20px">
          <li class="col mt-2"><a href="#" class="btn btn-outline btn-dark btn-facebook btn-block"><em
                class="fab fa-facebook-f"></em><span>Facebook</span></a></li>
          <li class="col mt-2"><a href="#" class="btn btn-outline btn-dark btn-google btn-block"><em
                class="fab fa-google"></em><span>Google</span></a></li>
        </ul> -->
          <!-- <div class="gaps-2x"></div>
        <div class="form-note text-center">
          Don’t have an account? <a [routerLink]="['/register']" routerLinkActive="router-link-active"
            class="primaryColor"> <strong>Sign up here</strong></a>
        </div> -->
        </div>
      </div>
    </div>
    <div class="page-ath-gfx">
      <div class="login-l-lang-drop">
        <div id="google_translate_element" style="float:left; padding-left:15px"></div>
        <div class="dropdown lang-dropdown" style="float: inline-end;">
          <button class="btn btn-secondary dropdown-toggle" type="button" style="background: none;"
            id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
            <img id="lang-img" src="assets/images/eng.svg" alt=" Flag" /> <span style="margin-left: 6px;"
              id="langswitch">English</span>
          </button> 
          <ul class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton1">
            <li><a class="dropdown-item" id="english" style="display: none;" onclick="changelang('English','assets/images/eng.svg','en','en');
                    return false;" href="#"><img src="assets/images/eng.svg" class="lang-img1" alt=" Flag" />
                <span style="margin-left: 6px;"> English </span> </a></li>
            <li>
              <a class="dropdown-item" id="turkish"
                onclick="changelang('Turkish','assets/images/tr.svg','en','tr');return false;" href="#"><img
                  class="lang-img1" src="assets/images/tr.svg" alt=" Flag" />
                Turkish</a>
            </li>
            <li><a class="dropdown-item" id="portuguese"
                onclick="changelang('Portuguese','assets/images/pt.svg','en','pt');return false;" href="#"><img
                  class="lang-img1" src="assets/images/pt.svg" alt=" Flag" />
                Portuguese</a></li>

          </ul>
        </div>
      </div>
    </div>
  </div>
  <!-- JavaScript (include all script here) -->
  <script src="assets/js/jquery.bundle.js"></script>
  <script src="assets/js/script.js"></script>
</body>
<!-- <app-modal-popup *ngIf="showPopup" [marginTop]="10">
    <app-verify-otp [email]="f.email.value" (eventChanged)="valueChanged($event)"></app-verify-otp>
</app-modal-popup> -->